<template>
  <div class="status-boxes">
    <div class="row">
      <div
        @click="emitStatusBoxClicked(box.id)"
        v-for="box in statusBoxes"
        :key="box.id"
        class="box-block col-md-6 col-lg-4 col-xl col-12"
        :class="{ active: selectedStatusBox === box.id }"
      >
        <div class="h-100">
          <icerik-status-boxes-info-box
            :is-active="selectedStatusBox === box.id"
            :selected-status-box="selectedStatusBox"
            :box="box"
            :from_icerikler_page="from_icerikler_page"
            :customStyle="customStyle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcerikStatusBoxesInfoBox from './IcerikStatusBoxesInfoBox.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'IcerikStatusBoxes',
  components: {
    IcerikStatusBoxesInfoBox,
  },
  props: {
    statusBoxes: {
      type: Array,
      default() {
        return []
      },
    },
    customStyle: {
      type: String,
      default: '',
    },
    selectedStatusBox: {
      type: Number,
      default: 1,
    },
    from_icerikler_page: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitStatusBoxClicked(i) {
      this.$emit('status-box-clicked', i)
    },
  },
  computed: {
    ...mapGetters(['projectDetail']),
  },
  mounted() {},
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
.status-boxes
  .box-block
    font-size: 0
    margin-bottom: 20px
</style>
