import Vue from 'vue'
const settings = {
  state: {
    settings: null,
    languageData: null
  },

  getters: {
    settings(state) {
      return state.settings
    },
    getLanguageData(state) {
      return state.languageData
    }
   },

  actions: {
    setSettings({ commit }, payload) {
      commit('setSettingsHandler', payload)
    },
    setLanguageData({ commit }, payload) {
      commit('setLanguageDataHandler', payload)
    },

    updateSettings({ commit }, payload) {
      Vue.prototype.$api.get(`edmin/settings`).then(response => {
        commit('updateSettingsHandler', response.data)
      })
    },
  },

  mutations: {
    setLanguageDataHandler(state, payload) {
      state.languageData = payload
    },
    setSettingsHandler(state, payload) {
      state.settings = payload
    },
    updateSettingsHandler(state, payload) {
      state.settings = payload
    },
  },
}

export default settings
