<template>
  <div class="table-footer">
    <div class="table-pagination px-3">
      <div class="table-count">
        <span class="text-gray f-dmsans">Göster</span>

        <select
          v-if="!forMessages"
          v-model.number="selectedLimit"
          class="brd-6 pagination-limit-selector bg-white cursor-pointer"
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
        <select
          v-if="forMessages"
          v-model.number="selectedLimit"
          class="brd-6 pagination-limit-selector bg-white cursor-pointer"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="100">100</option>
        </select>
      </div>

      <div class="table-arrows text-gray">
        <span>{{ currentPage }}-{{ totalPages }} toplam {{ totalItems }}</span>
        <div class="buttons">
          <a @click="emitGoToPrevPage" class="prev"
            ><i class="icon-chevron-left cursor-pointer"></i
          ></a>
          <a @click="emitGoToNextPage" class="next"
            ><i class="icon-chevron-right cursor-pointer"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IcerikPagination',
  data() {
    return {
      selectedLimit: 20,
    }
  },

  props: {
    currentPage: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
    totalItems: {
      type: Number,
    },
    forMessages: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    selectedLimit: {
      handler(newVal) {
        this.$emit('limit-changed', newVal)
      },
    },
    forMessages: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedLimit = 5
        }
      },
    },
  },
  methods: {
    emitGoToPrevPage() {
      this.$emit('go-to-prev-page')
    },

    emitGoToNextPage() {
      this.$emit('go-to-next-page')
    },
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
.table-footer
  padding: 20px 0 10px 0
  border-top: 1px solid #F7F8FF
  .table-pagination
    font-family: $dr
    text-align: right
    font-size: 12px
    .table-count
      display: inline-block
      vertical-align: middle
.pagination-limit-selector
  display: inline-block
  vertical-align: middle
  width: unset
  padding: 0px 25px 0px 10px
  background-position: 85% 50%
  margin-left: 10px
  font-size: 14px
.table-arrows
  display: inline-block
  vertical-align: middle
  margin-left: 15px
.buttons
  display: inline-block
  vertical-align: middle
  margin-left: 10px
  font-size: 24px
</style>
