const userTypeEnum = {
  admin: 1,
  publisher: 2,
  writer: 3,
  writerCandidate: 4,
  editor: 5,
  accounting: 6,
}

Object.freeze(userTypeEnum);

export default userTypeEnum;