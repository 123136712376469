<template>
  <div class="editor-controls d-flex align-items-center" v-if="!hideControls">
    <div v-if="savedTimeAgo" class="text-gray">
      {{ savedTimeAgo }} kaydedildi
    </div>
    <div
      class="d-flex align-items-center"
      v-if="!hideSaveButton"
      @click="$emit('on-save-content')"
    >
      <icerik-loading v-if="save_button_clicked" />
      <i v-tooltip.top="'Kaydet'" class="icon-save"></i>
    </div>

    <!-- <div
      class="cursor-pointer"
      v-if="!hidePasteButton"
      @click="$emit('on-paste')"
    >
      <i v-tooltip.top="'Yapıştır'" class="icon-paste"></i>
    </div> -->

    <div
      class="d-flex align-items-center"
      v-if="!hideFullscreenButton"
      @click="$emit('on-fullscreen-toggle')"
    >
      <i v-tooltip="`${toolTipComputed}`" :class="fullscreenIcon"></i>
    </div>

    <div
      class="cursor-pointer"
      v-if="!hidePreviewButton"
      @click="$emit('on-toggle-preview')"
    >
      <img
        v-tooltip.top="'Önizleme'"
        src="../../../../assets/images/icons/eye-open.svg"
        alt="Önizleme"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditorControls',
  props: {
    hideSaveButton: {
      type: Boolean,
      default: false,
    },

    hidePasteButton: {
      type: Boolean,
      default: false,
    },

    hideFullscreenButton: {
      type: Boolean,
      default: false,
    },
    inReviseMode: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    hidePreviewButton: {
      type: Boolean,
      default: false,
    },
    savedTimeAgo: {
      type: String,
    },
    fullscreenIcon: {
      type: String,
      default() {
        return 'icon-full-screen'
      },
    },
    save_button_clicked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toolTipComputed() {
      return this.fullscreenIcon === 'icon-exit-full-screen'
        ? 'Tam ekrandan çık'
        : 'İçeriği tam ekran görüntüle'
    },
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
.editor-controls
  display: flex
  div
    margin-right: 16.5px
    &:last-child
      margin-right: 0
  i
    font-size: 24px
    cursor: pointer
    color: $gray
.fullscreen
  .editor-controls
    height: 50px
.tooltip
  z-index: 9999 !important
</style>
