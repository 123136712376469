<template>
  <div class="radio-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RadioGroup',
}
</script>

<style lang="sass" scoped>
.radio-group
  display: flex
  align-items: center
</style>
