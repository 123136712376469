const routeEnums = {
  DASHBOARD: '/',
  PROJECTS: '/projeler',
  PROJECT_DETAIL: ':projectID',
  CONTENTS: '/icerikler',
  CONTENT_DETAIL: ':contentID',
  USERS: '/kullanicilar',
  USER_DETAIL: ':userID',
  APPLICATIONS: '/basvurular',
  APPLICATION_DETAIL: ':applicationID',
  COUPONS: '/indirim-kodlari',
  WRITER_PAYMENTS: '/yazar-odemeleri',
  BILLS: '/faturalar',
  DISCOUNT_CODES: '/indirim-kodlari',
  MESSAGES: '/mesajlar',
  MESSAGE_DETAIL: '/mesajlar/:messageID',
  CREATE_NEW_MESSAGE: '/mesajlar/yeni-mesaj',
  BLOG: '/blog',
  BLOG_DETAIL: ':blogID',
  BLOG_NEW: 'yeni',
  NEW_BLOG_POST: '/blog/yeni-blog',
  SETTINGS: {
    MAIN: '/ayarlar',
  },
  CREATE_PREMIUM_PROJECT: '/premium-proje-olustur/:projectID?',
  INVITATIONS: '/davetiyeler',
}

Object.freeze(routeEnums)

export default routeEnums
