<template>
  <div
    role="radio"
    :aria-checked="checked"
    :class="{ 'v-radio-group': radioGroup }"
    v-if="show"
    class="easy-radio"
  >
    <label
      :aria-labelledby="text"
      v-if="labelOnLeft && text"
      :style="computedStyle"
      >{{ text }}</label
    >
    <slot name="text-left"></slot>

    <input
      type="radio"
      class="custom"
      :name="name"
      :value="value"
      @change="handleOnChange"
      :checked="checked"
      :disabled="disabled"
    />
    <slot name="text-right"></slot>

    <label
      :aria-labelledby="text"
      v-if="!labelOnLeft && text"
      :style="computedStyle"
      >{{ text }}</label
    >
  </div>
</template>

<script>
export default {
  name: 'IcerikRadio',
  watch: {},
  methods: {
    handleOnChange(event) {
      const { checked } = event.target
      if (Array.isArray(this.modelValue)) {
        const modelArr = [...this.modelValue]
        if (checked) {
          modelArr.push(this.value)
        } else {
          modelArr.splice(modelArr.indexOf(this.value), 1)
        }
        this.$emit('change', modelArr)
      } else if (typeof this.modelValue === 'boolean') {
        this.$emit('change', checked)
      } else if (typeof this.modelValue === 'object') {
        this.$emit('change', this.value)
      }
    },
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    name: {
      type: String,
    },
    modelValue: {
      type: [Boolean, Array, Object, String, Number],
    },
    value: {
      type: [Boolean, Array, Object, String, Number],
    },
    checked: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: String,
    },
    radioGroup: {
      type: Boolean,
    },
    customStyle: {
      type: Object,
      default() {
        return {}
      },
    },
    labelOnLeft: {
      type: Boolean,
      default() {
        return false
      },
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    show: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {
    computedStyle() {
      const { customStyle } = this
      return {
        color: customStyle.color || '#3D4066',
        fontSize: customStyle.fontSize || '14px',
        marginLeft: customStyle.marginLeft || '8px',
        marginRight: customStyle.marginRight || '8px',
      }
    },
  },
}
</script>
<style lang="sass" scoped>
@import '~Assets/sass/main'
@supports (-webkit-appearance: none) or (-moz-appearance: none)
  input[type='checkbox'].custom,
  input[type='radio'].custom
    --active: #275EFE
    --active-inner: #fff
    --focus: 2px rgba(39, 94, 254, .3)
    --border: #BBC1E1
    --border-hover: #275EFE
    --background: #fff
    --disabled: #F6F8FF
    --disabled-inner: #E1E6F9
    -webkit-appearance: none
    -moz-appearance: none
    height: 21px
    outline: none
    display: inline-block
    vertical-align: top
    position: relative
    margin: 0
    cursor: pointer
    border: 1px solid #E8EAFA
    background: #F7F8FF
    transition: background .3s, border-color .3s, box-shadow .2s
    &:after
      content: ''
      display: block
      left: 0
      top: 0
      position: absolute
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s)
    &:checked

    &:disabled
      --b: var(--disabled)
      cursor: not-allowed
      opacity: .9
      &:checked
        --b: var(--disabled-inner)
        --bc: var(--border)

      & + label
        cursor: not-allowed
    &:not(.switch)
      width: 21px
      &:after
        opacity: var(--o, 0)

      &:checked
        --o: 1


    & + label
      font-size: 14px
      line-height: 21px
      display: inline-block
      vertical-align: top
      cursor: pointer
      margin-left: 5px
      margin-right: 13px
      font-family: $dr
      color: #3D4166

  input[type='radio'].custom
    border-radius: 50%
    &:after
      width: 11px
      height: 11px
      border-radius: 50%
      background: #4B52A6
      left: 3px
      top: 3px
      opacity: 0
      transform: scale(var(--s, .7))
    &:checked
      border: 2px solid #4B52A6
    & + label
      font-family: $dr
      color: #4B52A6
</style>
