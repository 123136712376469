import axios from 'axios'
import store from '../store'
import Vue from 'vue'

const initAxios = () => {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  axiosInstance.interceptors.request.use(
    config => {
      if (store.getters.token) {
        config.headers['Authorization'] = `Bearer ${store.getters.token}`
      }
      return config
    },
    error => Promise.reject(error)
  )

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config
      if ([401, 403].includes(error.response.status)){
        store.dispatch('removeAuthToken')
        const loginUrl = new URL(import.meta.env.VITE_WEB_URL);
        loginUrl.pathname = 'giris-yap';
        window.location.replace(loginUrl)
      } else if (error.response.status === 404 && !originalRequest._retry) {
        originalRequest._retry = true
      } else if (error.response.status === 422) {
        Vue.notify({
          group: 'error',
          text: error.response.data.detail[0].msg,
          type: 'error',
        })
      }
      // Do something with response error
      else if (error.response.status === 400) {
        Vue.notify({
          group: 'error',
          text: error.response.data.detail,
          type: 'error',
        })
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

const AxiosPlugin = {
  install(Vue) {
    const instance = initAxios()
    Vue.prototype.$api = instance
  },
}

export default AxiosPlugin
