<template>
  <box v-if="$route.name !== 'DoneStep'" classes="box bg-white brd-16">
    <div class="step-list">
      <div
        v-for="(step, i) in steps"
        class="step"
        :key="`step_${i}`"
        :class="{
          active: !fromPublisher
            ? step.name === $route.name
            : currentStep === i,
          back: currentStep > i,
        }"
      >
        <div class="step-count"></div>
        <div class="step-name">{{ step.text }}</div>
      </div>
    </div>
  </box>
</template>

<script>
import Box from './Box.vue';
import { mapGetters } from 'vuex'
export default {
  name: 'IcerikStepList',
  components: {
    Box,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    fromPublisher: {
      type: Boolean,
    },
    currentStep: {
      type: Number,
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
div.step-list
  text-align: center
  counter-reset: step
  div.step
    display: inline-block
    text-align: center
    width: 110px
    text-align: center
    margin: 0px 30px
    position: relative
    cursor: pointer
    &:last-child
      &:after
        display: none
    &:after
      content: ""
      position: absolute
      width: 90px
      height: 2px
      top: 20px
      right: -75px
      background-color: $light
    div.step-count
      width: 40px
      height: 40px
      background-color: $light
      border-radius: 100px
      display: block
      font-family: $dm
      font-size: 16px
      color: $gray
      line-height: 40px
      text-align: center
      margin: 0 auto
      margin-bottom: 10px
      &:before
        counter-increment: step
        content: counter(step)
    div.step-name
      font-family: $dm
      font-size: 12px
      color: $gray
  div.step.active
    div.step-count
      background-color: $primary
      color: #fff
    div.step-name
      color: $primary
  div.step.back
    &:after
      background-color: $gray
    div.step-count
      background-color: $gray
      color: #fff
      &:before
        font-family: 'icomoon'
        content: "\e905"
        font-size: 22px
</style>
