<template>
  <modal
    :adaptive="true"
    height="auto"
    name="set-password-modal"
    @closed="handleOnClosed"
  >
    <div class="modal-top">
      <div class="modal-title">Tam Yetkili Hesaba Yükselt</div>
      <button @click="$modal.hide('set-password-modal')">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-content">
      <div v-if="!getPassword">
          Tüm ayarlara erişebilmen için tam yetkili hesaba ait şifreyi girmen gerekmekte.
      </div>
      <div v-else class="custom-success-alert d-flex align-items-center">
          <span>
              <svg height="36" viewBox="0 0 21 21" width="36" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)"><path d="m12.8571123 1.79063546c-3.70547974-2.40636667-8.66011018-1.35322746-11.06647684 2.35225226-2.40636667 3.70547972-1.35322746 8.66011018 2.35225226 11.06647678 1.40713892.9138067 2.9944136 1.3287299 4.55387082 1.2889715 2.54712886-.0649393 5.02004606-1.3428829 6.51260596-3.6412237 1.5774991-2.4291355 1.6682799-5.39509184.4997393-7.82805117"/><path d="m4.5 7.5 3 3 8-8"/></g></svg>
          </span>
          <div class="pl-3">
              Admin hesabı tam yetkilendirildi. Yetkilendirmeyi kaldırmak için <b>"Yetkiyi Kaldır"</b> butonuna basman yeterli!
          </div>
      </div>
    </div>
    <div class="modal-form" v-if="!getPassword">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="form-item">
              <input
                v-model="password"
                type="password"
                class="brd-6"
                placeholder="Şifre giriniz."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="modal-footer">
          <div class="buttons">
            <icerik-button
              variant="light"
              margin-right="12px"
              @click="$modal.hide('set-password-modal')"
              >Vazgeç</icerik-button
            >
            <icerik-button v-if="!getPassword" variant="primary" @click="$emit('approveClicked', password)"
              >Onayla</icerik-button
            >
            <icerik-button v-if="getPassword" variant="danger" @click="$emit('removeClicked')"
              >Yetkiyi Kaldır</icerik-button
            >
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: 'SetPasswordModal',
  data() {
    return {
      password: null,
    }
  },
  mounted() {},
  methods: {
      handleOnClosed() {
          this.password = null
      }
  },
  computed: {
      ...mapGetters(['getPassword']),
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'

input
    font-family: $dr
    font-size: 13px !important

.custom-success-alert
    padding: 18px
    border-radius: 6px
    background-color: #D9EFCA
    color: #285706
    font-family: $dr
</style>
