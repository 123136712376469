<template>
  <div
    class="text-dark"
    :class="{ editor: !isFullscreen, 'editor-fullscreen': isFullscreen }"
  >
    <div v-if="contentTitle" class="editor-top" style="">
      <div class="block-title d-flex align-items-center">
        {{ contentTitle }}

        <span
          v-if="
            (contentSubmissionStatusId === 320 ||
              contentSubmissionStatusId === 340) &&
              !isFullscreen
          "
          @click="$emit('on-show-revise')"
          class="bg-pink text-white ml-3"
          >Revizeyi göster</span
        >
      </div>

      <editor-controls
        :in-revise-mode="inReviseMode"
        :hide-controls="hideControls"
        @on-fullscreen-toggle="$emit('on-fullscreen-toggle')"
        @on-paste="$emit('on-paste')"
        @on-save-content="saveContent"
        :hide-paste-button="hidePasteButton"
        :hide-fullscreen-button="hideFullscreenButton"
        :hide-save-button="hideSaveButton"
        :hide-preview-button="hidePreviewButton"
        :saved-time-ago="savedTimeAgo"
        :save_button_clicked="save_button_clicked"
      />
    </div>
    <div :id="holderId" @keydown="$emit('copy_paste_detected', $event)"></div>
  </div>
</template>

<script>
import EditorJS from '@editorjs/editorjs'
import ReviseTool from './custom-tools/revise-tool/ReviseTool'
import Delimiter from '@editorjs/delimiter'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Table from '@editorjs/table'
import ReviseContent from './custom-tools/revise-content/ReviseContent'
import InlineImage from 'editorjs-inline-image'
import EditorControls from './components/EditorControls'
import messages from './messages'

import {
  convertToArray,
  convertToHTML,
  getWordCountFromArray,
  clearString,
  convertArrayToString,
  delete_marks,
} from './helpers/helperFunctions.js'

export default {
  name: 'Editor',
  components: {
    EditorControls,
  },
  data() {
    return {
      content: null,
      html: null,
      revises: [],
    }
  },

  props: {
    contentSubmissionStatusId: {
      type: Number,
    },
    token: {
      type: String,
    },

    contentTitle: {
      type: String,
    },

    userType: {
      type: Number,
      required: true,
    },

    allowImages: {
      type: Boolean,
    },

    savedContent: {
      type: Object,
      default() {
        return {}
      },
    },

    isFullscreen: {
      type: Boolean,
      default() {
        return false
      },
    },

    holderId: {
      type: String,
      required: true,
    },

    minHeight: {
      type: Number,
    },

    savedTimeAgo: {
      type: String,
    },

    inReviseMode: {
      type: Boolean,
      default() {
        return false
      },
    },

    inReviseEditMode: {
      type: Boolean,
      default() {
        return false
      },
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
    hideFullscreenButton: {
      type: Boolean,
      default: false,
    },
    hidePasteButton: {
      type: Boolean,
      default: false,
    },
    hidePreviewButton: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    hideControls: {
      type: Boolean,
      default() {
        return false
      },
    },
    save_button_clicked: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    save() {
      window[this.holderId].save().then(saved => (this.content = saved))
    },

    emitEditorData(data, eventName) {
      if (Object.keys(data).length > 0) {
        const wordCount = getWordCountFromArray(data)
        const contentHTML = convertToHTML(data)
        const contentAsString = convertArrayToString(data)
        this.html = clearString(contentHTML)
        this.$emit(eventName, {
          content: this.content,
          wordCount,
          contentHTML,
          contentAsString,
        })
      }
    },

    saveContent() {
      window[this.holderId].save().then(content => {
        this.emitEditorData(content, 'on-content-save')
        // this.$emit('on-content-save', saved)
      })
    },

    pasteFromWord() {
      this.$emit('on-paste')
    },

    generateImageConfig() {
      return {
        class: InlineImage,
        inlineToolbar: true,
        config: {
          embed: {
            display: false,
          },
          unsplash: {
            appName: 'React Photo Gallery',
            clientId:
              'ec5e525030ed007e20dfb01abe5e917c2265e1b57f599b0be25945130b8e93b8',
          },
        },
      }
    },

    generateToolsConfig() {
      return {
        header: {
          class: Header,
          config: {
            placeholder: 'Başlık giriniz',
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 4,
          },
          inlineToolbar: true,
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        table: {
          class: Table,
          inlineToolbar: true,
        },
      }
    },
  },

  computed: {
    writerBasicEditorConfig() {
      if (this.savedContent) {
        this.content = this.savedContent
      }
      return {
        autofocus: true,
        holder: this.holderId,
        minHeight: this.minHeight,
        i18n: {
          ...messages,
        },
        onReady: api => {
          // passing editor instance?
          this.$emit('editor-is-ready', window[this.holderId])

          if (this.content) {
            this.emitEditorData(this.content, 'editor-content-changed')
          }
        },

        onChange: api => {
          window[this.holderId].save().then(content => {
            this.content = content
            this.emitEditorData(this.content, 'editor-content-changed')
          })
        },

        data: delete_marks(this.content),
        logLevel: 'ERROR',

        placeholder: this.placeholder,
        tools: {
          ...this.generateToolsConfig(),
        },
      }
    },

    writerAllowImagesEditorConfig() {
      // Extend basic editor config for writer
      this.writerBasicEditorConfig.tools.inlineImage = this.generateImageConfig()
      return this.writerBasicEditorConfig
    },

    publisherBasicEditorConfig() {
      // Get saved content if there is one already
      if (this.savedContent) {
        this.content = this.savedContent
      }

      return {
        autofocus: true,
        holder: this.holderId,
        minHeight: this.minHeight,
        i18n: {
          ...messages,
        },
        onReady: api => {
          // passing editor instance?
          this.$emit('editor-is-ready', window[this.holderId])

          if (this.content) {
            this.emitEditorData(this.content, 'editor-content-changed')
          }
        },

        onChange: api => {
          window[this.holderId].save().then(content => {
            this.content = content

            this.emitEditorData(content, 'editor-content-changed')
          })
        },

        data: this.content,
        logLevel: 'ERROR',
        placeholder: this.placeholder,
        tools: {
          ...this.generateToolsConfig(),
          Revize: ReviseTool,
          ReviseContent: ReviseContent,

          inlineImage: {
            ...this.generateImageConfig(),
          },
        },
      }
    },

    publisherCustomizedEditorConfig() {
      if (this.allowImages) {
        this.publisherBasicEditorConfig.tools.inlineImage = this.generateImageConfig()
      }

      return {
        ...this.publisherBasicEditorConfig,
      }
    },

    computedCurrentEditorConfig() {
      switch (this.userType) {
        case 3:
          if (!this.allowImages) return this.writerBasicEditorConfig
          return this.writerAllowImagesEditorConfig
        case 2:
          return this.publisherBasicEditorConfig
        case 1:
          return this.publisherBasicEditorConfig
      }
    },
  },

  mounted() {
    window[this.holderId] = new EditorJS(this.computedCurrentEditorConfig)
  },
}
</script>

<style lang="sass">
@import '~Assets/sass/main'
#writer_editor_main_section
  #editorContent
    div.codex-editor__redactor
      padding-bottom: 0 !important
      min-height: 550px
.editor-fullscreen
  .block-title
    font-size: 32px!important
    display: flex
    align-items: center
  .editor-top
    padding-top: 0!important
    margin-bottom: 16px !important
.editor-top
  display: flex
  align-items: center
  justify-content: space-between
  padding-top: 20px
  .block-title
    font-family: $dm
    font-size: 16px
    color: #3D4166
    margin-bottom: 0
    span
      height: 24px
      padding: 0px 10px
      line-height: 24px
      border-radius: 4px
      font-family: $dm
      font-size: 12px
      color: #fff
      display: inline-block
      margin-left: 5px
      cursor: pointer
::v-deep
  .ce-block__content
    max-width: 100%
  .cdx-notifies
    display: none!important
  .ce-toolbar__content
    max-width: 100%
    margin: 0 32px
.revise-tooltip
  width: 100px
  height: 50px
  background: gray
  position: absolute
  border-radius: 6px
  color: white
  text-align: center
  z-index: 100
.ce-paragraph[data-placeholder]:empty::before
  color: $gray
.ce-block__content
  margin: 0!important
  max-width: 100%
.ce-toolbar__content
  margin: inherit !important
.inline-image__image-credits, .inline-image__caption:not(#image-url):not(#unsplash-search), #image-url, #embed-button
  display: none
.ce-table
  width: 100%
  border: 1px solid $light2
  margin: 1rem 0
  font-family: $dr
  tr
    white-space: nowrap
    transition: .25s
    &:hover
     background-color: rgba(232, 234, 250, .30)
    td
      border-bottom: 1px solid $light2
      border-right: 1px solid $light2
      width: 1%
      white-space: nowrap
      padding: 12px
      font-size: 14px
</style>
