const userDetail = {
  state: {
    userDetail: null,
    userDetailPublisherFormData: null,
    userDetailWriterFormData: null,
    currentEditor: localStorage.getItem('current_selected_editor') || null,
  },

  getters: {
    userDetail(state) {
      return state.userDetail
    },
    userDetailPublisherFormData(state) {
      return state.userDetailPublisherFormData
    },
    userDetailWriterFormData(state) {
      return state.userDetailWriterFormData
    },
    getSelectedProjectManager(state) {
      return JSON.parse(state.currentEditor)
    },
  },

  actions: {
    setUserDetail({ commit }, payload) {
      commit('setUserDetailHandler', payload)
    },
    setUserDetailPublisherFormData({ commit }, payload) {
      commit('setUserDetailPublisherFormDataHandler', payload)
    },
    setUserDetailWriterFormData({ commit }, payload) {
      commit('setUserDetailWriterFormDataHandler', payload)
    },
    setSelectedProjectManager({ commit }, payload) {
      commit('setSelectedProjectManagerHandler', payload)
    },
  },

  mutations: {
    setUserDetailHandler(state, payload) {
      state.userDetail = payload
    },

    setUserDetailPublisherFormDataHandler(state, payload) {
      state.setUserDetailPublisherFormData = payload
    },
    setUserDetailWriterFormDataHandler(state, payload) {
      state.userDetailWriterFormData = payload
    },
    setSelectedProjectManagerHandler(state, payload) {
      localStorage.setItem('current_selected_editor', JSON.stringify(payload))
      state.currentEditor = JSON.stringify(payload)
    },
  },
}

export default userDetail
