import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import store from '../store/index'
import Dashboard from 'Views/Dashboard'

const Applications = () => import('Views/Applications')
const ApplicationDetail = () => import('Views/ApplicationDetail')
const Projects = () => import('Views/Projects')
const ProjectDetail = () => import('Views/ProjectDetail')
const Contents = () => import('Views/Contents')
const Users = () => import('Views/Users')
const UserDetail = () => import('Views/UserDetail')
const ContentDetail = () => import('Views/ContentDetail')
const WriterPayments = () => import('Views/WriterPayments')
const Bills = () => import('Views/Bills')
const Coupons = () => import('Views/Coupons')
const Messages = () => import('Views/Messages')
const Blog = () => import('Views/Blog')
const BlogDetail = () => import('Views/BlogDetail')
const Settings = () => import('Views/Settings')
const MessageDetail = () => import('Views/MessageDetail.vue')
const NewPremiumProject = () => import('Views/NewPremiumProject.vue')
const Invitations = () => import('Views/Invitations.vue')

import RouterViewWrapper from './RouterViewWrapper'

import routeEnums from './routeEnums'

const routes = [
  {
    path: routeEnums.DASHBOARD,
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      breadcrumb: 'Anasayfa',
      page: {
        title: 'Merhaba',
        hasBreadcrumbs: false,
        subtitle: 'Admin paneline hoş geldiniz',
      },
      title: 'Admin | Dashboard'
    },
  },

  {
    path: routeEnums.APPLICATIONS,
    component: RouterViewWrapper,
    meta: {
      breadcrumb: {
        label: 'Başvurular',
        parent: 'Dashboard',
      },
    },
    children: [
      {
        path: '',
        name: 'Applications',
        component: Applications,
        meta: {
          page: {
            hasBreadcrumbs: true,
            title: 'Başvurular',
          },
          title: 'Admin | Yazar Başvuruları'
        },
      },
      {
        path: routeEnums.APPLICATION_DETAIL,
        name: 'ApplicationDetail',
        component: ApplicationDetail,
        meta: {
          breadcrumb() {
            const { params } = this.$route
            return {
              label: params.applicationTitle
                ? params.applicationTitle
                : params.applicationID,
            }
          },
          page: {
            title: 'Başvurular',
            hasBreadcrumbs: true,
          },
          title: 'Admin | Yazar Başvuruları'
        },
      },
    ],
  },
  {
    path: routeEnums.PROJECTS,
    component: RouterViewWrapper,
    meta: {
      breadcrumb: {
        label: 'Projeler',
        parent: 'Dashboard',
      },
    },
    children: [
      {
        path: '',
        name: 'Projects',
        component: Projects,
        meta: {
          page: {
            hasBreadcrumbs: true,
            title: 'Projeler',
          },
          title: 'Admin | Projeler'
        },
      },
      {
        path: routeEnums.PROJECT_DETAIL,
        name: 'ProjectDetail',
        component: ProjectDetail,
        meta: {
          breadcrumb() {
            const { params } = this.$route
            return {
              label: params.projectTitle
                ? params.projectTitle
                : params.projectID,
            }
          },
          page: {
            title: 'Projeler',
            hasBreadcrumbs: true,
          },
          title: 'Admin | Projeler'
        },
      },
    ],
  },
  {
    path: routeEnums.CONTENTS,
    component: RouterViewWrapper,
    meta: {
      breadcrumb: {
        label: 'İçerikler',
        parent: 'Dashboard',
      },
    },
    children: [
      {
        path: '',
        name: 'Contents',
        component: Contents,
        meta: {
          page: {
            hasBreadcrumbs: true,
            title: 'İçerikler',
          },
          title: 'Admin | İçerikler'
        },
        
      },
      {
        path: routeEnums.CONTENT_DETAIL,
        name: 'ContentDetail',
        component: ContentDetail,
        meta: {
          breadcrumb() {
            const { params } = this.$route
            return {
              label: params.contentTitle
                ? params.contentTitle
                : params.contentID,
            }
          },
          page: {
            title: 'İçerikler',
            hasBreadcrumbs: true,
          },
          title: `Admin | İçerik Detay`
        },
      },
    ],
  },
  {
    path: routeEnums.USERS,
    component: RouterViewWrapper,
    meta: {
      breadcrumb: {
        label: 'Kullanıcılar',
        parent: 'Dashboard',
      },
    },
    children: [
      {
        path: '',
        name: 'Users',
        component: Users,
        meta: {
          page: {
            hasBreadcrumbs: true,
            title: 'Kullanıcılar',
          },
          title: 'Admin | Kullanıcılar'
        },
      },
      {
        path: routeEnums.USER_DETAIL,
        name: 'UserDetail',
        component: UserDetail,
        meta: {
          breadcrumb() {
            const { params } = this.$route
            return {
              label: params.userFullName ? params.userFullName : params.userID,
            }
          },
          page: {
            title: 'Kullanıcılar',
            hasBreadcrumbs: true,
          },
          title: 'Admin | Kullanıcı Detay'
        },
      },
    ],
  },
  {
    path: routeEnums.WRITER_PAYMENTS,
    name: 'WriterPayments',
    component: WriterPayments,
    beforeEnter(to, from, next) {
      if(store.getters.getPassword) {
        next()
      }
      else {
        next({
          path: '/',
          replace: true,
        })    
      }
    },
    meta: {
      breadcrumb: {
        parent: 'Dashboard',
        label: 'Yazar ödemeleri',
      },
      page: {
        hasBreadcrumbs: true,
        title: 'Yazar Ödemeleri',
      },
      title: 'Admin | Yazar Ödemeleri'
    },
  },

  {
    path: routeEnums.BILLS,
    name: 'Bills',
    component: Bills,
    beforeEnter(to, from, next) {
      if(store.getters.getPassword) {
        next()
      }
      else {
        next({
          path: '/',
          replace: true,
        })    
      }
    },
    meta: {
      breadcrumb: {
        parent: 'Dashboard',
        label: 'Faturalar',
      },
      page: {
        hasBreadcrumbs: true,
        title: 'Faturalar',
      },
      title: 'Admin | Faturalar'
    },
  },

  {
    path: routeEnums.COUPONS,
    name: 'Coupons',
    component: Coupons,
    meta: {
      breadcrumb: {
        parent: 'Dashboard',
        label: 'İndirim kodları',
      },
      page: {
        hasBreadcrumbs: true,
        title: 'İndirim kodları',
      },
      title: 'Admin | İndirim Kodları'
    },
  },

  {
    path: routeEnums.MESSAGES,
    name: 'Messages',
    component: Messages,
    meta: {
      breadcrumb: {
        parent: 'Dashboard',
        label: 'Mesajlar',
      },
      page: {
        hasBreadcrumbs: true,
        title: 'Mesajlar',
      },
      title: 'Admin | Mesajlar'
    },
  },

  {
    path: routeEnums.MESSAGE_DETAIL,
    name: 'MessageDetail',
    component: MessageDetail,
    meta: {
      breadcrumb: {
        label: 'Mesajlar',
        parent: 'Dashboard',
      },
      page: {
        title: 'Mesajlar',
        hasBreadcrumbs: true,
      },
      title: 'Admin | Mesajlar'
    },
  },

  {
    path: routeEnums.BLOG,
    component: RouterViewWrapper,
    meta: {
      breadcrumb: {
        label: 'Blog',
        parent: 'Dashboard',
      },
      title: 'Admin | Blog'
    },
    children: [
      {
        path: '',
        name: 'Blog',
        component: Blog,
        meta: {
          page: {
            hasBreadcrumbs: true,
            title: 'Blog',
          },
        },
      },
      {
        path: routeEnums.BLOG_NEW,
        name: 'BlogNew',
        component: BlogDetail,
        meta: {
          breadcrumb: {
            label: 'Yeni blog yazısı',
          },
          page: {
            title: 'Blog',
            hasBreadcrumbs: true,
          },
        },
      },
      {
        path: routeEnums.BLOG_DETAIL,
        name: 'BlogDetail',
        component: BlogDetail,
        meta: {
          breadcrumb() {
            const { params } = this.$route

            const getBlogBreadcrumbLabel = () => {
              if (params.blogTitle) {
                return params.blogTitle
              } else if (params.blogID) {
                return params.blogID
              } else {
                return 'Yeni Blog Yazısı'
              }
            }
            return {
              label: getBlogBreadcrumbLabel(),
            }
          },
          page: {
            title: 'Blog',
            hasBreadcrumbs: true,
          },
        },
      },
    ],
  },

  {
    path: routeEnums.SETTINGS.MAIN,
    name: 'Settings',
    component: Settings,
    beforeEnter(to, from, next) {
      if(store.getters.getPassword) {
        next()
      }
      else {
        next({
          path: '/',
          replace: true
        })    
      }
    },
    meta: {
      breadcrumb: {
        label: 'Ayarlar',
        parent: 'Dashboard',
      },
      page: {
        title: 'Ayarlar',
        hasBreadcrumbs: true,
      },
      title: 'Admin | Ayarlar'
    },
  },
  {
    path: routeEnums.CREATE_PREMIUM_PROJECT,
    name: 'NewPremiumProject',
    component: NewPremiumProject,
    meta: {
      breadcrumb: {
        label: 'Yeni Birebir proje',
        parent: 'Dashboard',
      },
      page: {
        title: 'Yeni Birebir proje',
        hasBreadcrumbs: true,
      },
      title: 'Admin | Birebir Proje'
    },
  },

  {
    path: routeEnums.INVITATIONS,
    name: 'Invitations',
    component: Invitations,
    meta: {
      breadcrumb: {
        label: 'Davetiyeler',
        parent: 'Dashboard',
      },
      page: {
        title: 'Davetiyeler',
        hasBreadcrumbs: true,
      },
      title: 'Admin | Davetiyeler'
    },
  },

  /*   {
      path: '/kullanicilar/:id/basvuru-sonuclari',
      name: 'ApplicationResults',
      component: ApplicationResults
    }*/
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if(!store.getters.getSelectedProjectManager && to.path !== '/') {
    next('/')
  }
  else {
    document.title = to.meta.title
    store.dispatch('setIsFullscreen', false)
    next()
  }
})


export default router
