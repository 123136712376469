<template>
  <div class="lds-dual-ring"></div>
</template>

<script>
export default {
  name: 'IcerikLoading',
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'

.lds-dual-ring
  display: flex
  justify-content: center
.lds-dual-ring:after
  content: ""
  display: block
  width: 20px
  height: 20px
  border-radius: 50%
  border: 3px solid $primary
  border-color: $primary transparent $primary transparent
  animation: lds-dual-ring 1.2s linear infinite
@keyframes lds-dual-ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
