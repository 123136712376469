const contentStatusEnums = {
  continues: [300, 400, 215],
  editorControl: [400, 410, 415, 420],
  editorRevise: [340],
  publisherControl: [200, 220],
  publisherRevise: [320],
  done: [290, 301, 390],
  cancel: [395, 399,3991, 299],
  pool: [30, 40],
}
Object.freeze(contentStatusEnums)

export default contentStatusEnums
