<template>
  <div id="app">
    <div id="general-contaienr" class="container-fluid">
      <div class="row">
        <the-sidebar-menu
          v-if="!isFullscreen"
          :user-data="profileData"
          :user-type="1"
          :hasFullAccess="getPassword ? true : false"
        />
        <main id="main" :class="{ fullscreen: isFullscreen }">
          <the-page-header
            v-if="!isFullscreen"
            :profile-data="profileData"
            :title="computedPageTitle"
            is-admin
            @notification-clicked="handleOnNotificationClicked"
            @upgradeAccountClicked="handleUpgradeAccountClicked"
          >
            <template
              #breadcrumb
              v-if="$route.meta.page && $route.meta.page.hasBreadcrumbs"
            >
              <Breadcrumbs />
            </template>
            <template v-else #subtitle>
              <span
                class="text-gray page-subtitle"
                v-if="$route.meta.page && $route.meta.page.subtitle"
              >
                {{ $route.meta.page.subtitle }}
              </span>
            </template>
          </the-page-header>
          <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath" />
          </transition>
          <notifications
            position="bottom center"
            :width="390"
            group="error"
            ignore-duplicates
            classes="alert-error"
          />
          <notifications
            position="bottom center"
            :width="390"
            group="success"
            ignore-duplicates
            classes="alert-success"
          />
        </main>
        <set-password-modal
          @approveClicked="handleOnApproveClicked($event)"
          @removeClicked="handleOnRemoveClicked"
        ></set-password-modal>
      </div>
    </div>

    <div v-if="isAppLoading" class="app-loader"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SetPasswordModal from './components/password/modals/SetPasswordModal.vue'

export default {
  components: {
    SetPasswordModal,
  },
  data() {
    return {
      isAppLoading: false,
    }
  },
  methods: {
    ...mapActions(['setProfileData', 'setPassword', 'deletePassword']),
    handleOnNotificationClicked() {
      this.getProfileData()
    },
    async getProfileData() {
      try {
        this.isAppLoading = true;
        const response = await this.$api.get('profile/me');
        this.setProfileData(response.data);
        
        const messagesResponse = await this.$api.get(`edmin/message?skip=0&limit=999&received=true`);
        const messages = messagesResponse.data;
        if (messages?.length) {
          messages
          .filter(message => !message.done)
          .forEach(message => {
            this.profileData?.main?.unread_messages.push(message);
          });
        }
        this.setProfileData(this.profileData);
      } catch (error) {
        console.error(error);
      } finally {
        this.isAppLoading = false;
      }
    },
    handleUpgradeAccountClicked() {
      this.$modal.show('set-password-modal')
    },
    handleOnApproveClicked(data) {
      const basePassword = import.meta.env.VITE_EDMIN_BASE_PSW
      if (data !== basePassword) {
        this.$notify({
          group: 'error',
          type: 'error',
          text: 'Hatalı şifre girildi.',
        })
      } else {
        this.setPassword(data)
        this.$notify({
          group: 'success',
          type: 'success',
          text: 'Yetkilendirme işlemi onaylandı.',
        })
        this.$modal.hide('set-password-modal')
      }
    },
    handleOnRemoveClicked() {
      this.deletePassword()
      this.$notify({
          group: 'success',
          type: 'success',
          text: 'Yetkilendirme işlemi başarıyla kaldırıldı.',
        })
        this.$modal.hide('set-password-modal')
    }
  },
  computed: {
    ...mapGetters(['isFullscreen', 'profileData', 'getPassword']),
    computedPageTitle() {
      if (this.$route.meta.page) {
        if (typeof this.$route.meta?.page?.title === 'function') {
          return this.$route.meta.page.title(this.$route)
        } else {
          return this.$route.meta.page.title
        }
      }
    },
  },
  mounted() {
    this.getProfileData()
  },
}
</script>

<style lang="sass">
@import '~Assets/sass/main'
@import '/fonts/icomoon/icomoon.css'
.breadcrumb
  margin-bottom: 30px
  font-family: $dm
  padding: 0
  margin: 0
  font-size: 0
  background: none
  li.breadcrumb-item
    display: inline-block
    font-size: 12px
    color: $primary
    &:last-child
      &:after
        display: none
    &:after
      margin: 0px 5px
      color: #9795A6
    &:hover
      color: #9795A6
    span.router-link-exact-active
      color: #9795A6
    a.router-link-exact-active
      color: #9795A6!important
    &:last-child
      a.router-link-active
        color: #9795A6!important
main#main
  flex: 0 0 100%
  -ms-flex: 0 0 100%
  max-width: calc(100% - 240px)
  padding: 30px
  margin-left: 240px
main#main.fullscreen
  padding: 0 !important
  max-width: 100%!important
  height: 100vh!important
  margin-left: 0!important
  width: 100%
.vue-notification-group
  z-index: 998 // Modals doesn't work if this would be higher than this value
  .vue-notification-wrapper
    margin-bottom: 24px
    .vue-notification-template
      height: 48px
      border-radius: 8px
      margin: 8px
      font-size: 14px
      display: flex
      align-items: center
    .alert-error
      background: $red-light
      color: #3D4166

      .notification-title
      .notification-content
        margin-left: 14px
      &.error

    .alert-success
      background: $green-light
      color: #3D4166

      .notification-title
      .notification-content
        margin-left: 14px
      &.success
  .custom-tooltip
    position: relative
    display: inline-block
    vertical-align: middle
    left: 5px
    top: 1px
  .tooltiptext
    visibility: hidden
    opacity: 0
    background-color: $gray
    color: #fff
    text-align: center
    padding: 0 15px
    border-radius: 6px
    position: absolute
    z-index: 1
    bottom: 110%
    min-width: 100px
    left: calc(50% - 50px)
    transition: opacity 0.3s
    font-family: $dm
    font-size: 12px
    color: #fff
    &:after
      content: ""
      position: absolute
      top: 100%
      left: 50%
      margin-left: -5px
      border-width: 5px
      border-style: solid
      border-color: $gray transparent transparent transparent
  &:hover
    .tooltiptext
      visibility: visible
      opacity: 1

.bg-light2
  background-color: $light2
.fade-enter-active, .fade-leave-active
  transition: opacity .1s

.fade-enter, .fade-leave-to
  opacity: 0.5
.vue-tooltip
  background-color: #868AB2 !important
  font-family: $dm
  font-size: 12px
  color: #fff
  box-shadow: none !important
  padding: 0px 10px
  border-radius: 6px
  .tooltip-arrow
    border-color: #868AB2 !important
.page-subtitle
  font-family: $dm
  font-size: 12px
  color: $gray

input::placeholder
    font-family: 'DM Sans', sans-serif
    font-weight: 400

select
  font-weight: 400
  font-family: 'DM Sans', sans-serif
  font-size: 14px !important
  transition: .2s
  cursor: pointer
  background-color: white
  &:hover
        border-color: #afacc2 !important
.primary-hover
  transition: .2s
  &:hover
   color: $primary  !important
</style>
<style lang="scss">
.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
}
</style>
