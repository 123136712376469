import contentStatusEnums from '@/enums/contentStatusEnums'
const projectDetail = {
  state: {
    projectDetail: null,
    contents: null,
  },

  getters: {
    contents(state) {
      return state.contents
    },

    projectDetail(state) {
      return state.projectDetail
    },
  },

  actions: {
    setProjectDetail({ commit }, payload) {
      commit('setProjectDetailHandler', payload)

      const contents = {
        pool: [],
        writer: [],
        waiting: [],
        revision: [],
        publisher: [],
        canceled: [],
        done: [],
        all: [],
      }

      payload.contents.forEach(c => {
        let lastSubmissionStatusId =
          c.last_submission?.content_submission_status_id
        if (contentStatusEnums.pool.includes(lastSubmissionStatusId)) {
          contents.pool.push(c)
        } if (
          contentStatusEnums.continues.includes(lastSubmissionStatusId)
        ) {
          contents.writer.push(c)
        } if (
          contentStatusEnums.editorControl.includes(lastSubmissionStatusId)
        ) {
          contents.waiting.push(c)
        } if (
          contentStatusEnums.publisherRevise.includes(lastSubmissionStatusId) ||
          contentStatusEnums.editorRevise.includes(lastSubmissionStatusId)
        ) {
          contents.revision.push(c)
        } if (
          contentStatusEnums.publisherControl.includes(lastSubmissionStatusId)
        ) {
          contents.publisher.push(c)
        } if (contentStatusEnums.cancel.includes(lastSubmissionStatusId)) {
          contents.canceled.push(c)
        } if (contentStatusEnums.done.includes(lastSubmissionStatusId)) {
          contents.done.push(c)
        }

        contents.all.push(c)
      })

      commit('setContentsHandler', contents)
    },
  },

  mutations: {
    setProjectDetailHandler(state, payload) {
      state.projectDetail = payload
    },
    setContentsHandler(state, payload) {
      state.contents = payload
    },
  },
}

export default projectDetail
