import {
  debounce,
  convertTimestampToDate,
  userAvatarName,
  getKeywordsAsArray,
  convertTimestampToHours,
  paginator,
  isDateBetween,
  filterByKeyword,
  filterArray,
  getContentDeadline,
  getNormalContentDeadline,
  getColorByStatus,
  getColorsByStatusForContentDetailHeader,
  getColorsByHour,
  getColorAndBgColorByContentStatus,
  getColorsByInterviewStatus,
  getColorAndBgColorByInterviewStatus,
  checkIfKeywordMatches,
  copyTextToClipboard,
  turkish_to_english
} from './functions'

export default {
  debounce,
  convertTimestampToDate,
  userAvatarName,
  getKeywordsAsArray,
  convertTimestampToHours,
  paginator,
  isDateBetween,
  filterByKeyword,
  filterArray,
  getContentDeadline,
  getNormalContentDeadline,
  getColorByStatus,
  getColorsByStatusForContentDetailHeader,
  getColorsByHour,
  getColorAndBgColorByContentStatus,
  getColorsByInterviewStatus,
  getColorAndBgColorByInterviewStatus,
  checkIfKeywordMatches,
  copyTextToClipboard,
  turkish_to_english
}
