const writerPayments = {
  state: {
    expenses: null,
  },

  getters: {
    expenses(state) {
      return state.expenses
    },
  },

  actions: {
    setExpenses({ commit }, payload) {
      commit('setExpensesHandler', payload)
    },
  },

  mutations: {
    setExpensesHandler(state, payload) {
      state.expenses = payload
    },
  },
}

export default writerPayments
