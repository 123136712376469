<template>
  <div class="step-footer">
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-0">
          <a href="#" class="step-button bg-primary text-white brd-6">{{ footerText }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "StepFooter",
    props: {
      footerText: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>