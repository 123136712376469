<template>
  <aside
    v-if="isSidebarActive && !isFullscreen"
    id="sidebar"
    class="row m-0"
    :class="{ 'sidebar-small': isSidebarSmallManually }"
  >
    <nav class="side-menu">
      <a
        class="cursor-pointer logo-container"
        :class="userType === userTypeEnum.writer ? 'mb-4' : ''"
        @click="$router.push({ name: 'Dashboard' })"
      >
        <span class="new-logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 935.83 120.11">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path class="cls-1" d="M236.35,38.79h18.57v78.9H236.35Z" />
                <path
                  class="cls-1"
                  d="M305.51,120.07a39.58,39.58,0,0,1-20.88-5.61,40,40,0,0,1-14.58-15.19,44.21,44.21,0,0,1,0-42,40.27,40.27,0,0,1,14.58-15.24,39.42,39.42,0,0,1,20.88-5.64,41.24,41.24,0,0,1,18.07,3.88A38,38,0,0,1,337.13,51a41.15,41.15,0,0,1,7.67,15.62h-19a19.09,19.09,0,0,0-7.9-9.71,23.37,23.37,0,0,0-12.36-3.26,21.57,21.57,0,0,0-10.93,3,22.44,22.44,0,0,0-8.33,8.52,25.89,25.89,0,0,0-3.15,13,26.21,26.21,0,0,0,3.15,13.05,22.22,22.22,0,0,0,8.33,8.59,21.48,21.48,0,0,0,10.93,3,24,24,0,0,0,8.56-1.5,19.26,19.26,0,0,0,7-4.64,20.52,20.52,0,0,0,4.68-7.91h18.89a41.31,41.31,0,0,1-7.53,16.23,37.46,37.46,0,0,1-13.62,11.06A41,41,0,0,1,305.51,120.07Z"
                />
                <path
                  class="cls-1"
                  d="M409.51,68.8a15.23,15.23,0,0,0-3.26-8.1,20.17,20.17,0,0,0-7.56-5.91,23.27,23.27,0,0,0-10-2.19,23,23,0,0,0-11.24,2.81,20.64,20.64,0,0,0-8.22,8.48q-3.11,5.67-3.1,14a29.6,29.6,0,0,0,3,13.62,21,21,0,0,0,19.3,11.94,24.74,24.74,0,0,0,8.14-1.23,15.68,15.68,0,0,0,5.95-3.61,16,16,0,0,0,3.72-5.75h20.26a35,35,0,0,1-7.29,13.51,39.19,39.19,0,0,1-13.12,9.94,41.85,41.85,0,0,1-38.23-1.92,40.21,40.21,0,0,1-14.66-15.2,42.58,42.58,0,0,1-5.29-21,42.86,42.86,0,0,1,5.29-21,39.8,39.8,0,0,1,35.62-20.84,39.07,39.07,0,0,1,20.6,5.49,38.31,38.31,0,0,1,14.16,14.81,41.52,41.52,0,0,1,5.07,20.15q0,1.38-.15,2.88c-.11,1-.18,2.13-.23,3.41H363.08l.84-14.35Z"
                />
                <path
                  class="cls-1"
                  d="M437.38,38.79h17.8V53.06a25.77,25.77,0,0,1,4.95-7.63,20.82,20.82,0,0,1,7.14-4.91,23.1,23.1,0,0,1,9.1-1.73h8.44v16h-7.45a20.5,20.5,0,0,0-11.2,3.07,20.15,20.15,0,0,0-7.52,9A34,34,0,0,0,456,81v36.69H437.38Z"
                />
                <path class="cls-1" d="M491.8,38.79h18.57v78.9H491.8Z" />
                <path
                  class="cls-1"
                  d="M525.81,117.69V9.62h18.57V117.69Zm46.35-78.9H594.8l-31,39.53-6.37,3.14L543.15,97V77.39Zm26,78.9H577.31l-21-36.3,13.59-12Z"
                />
                <path
                  class="cls-1"
                  d="M674,120.07a39.57,39.57,0,0,1-20.87-5.61,40,40,0,0,1-14.58-15.19,42.72,42.72,0,0,1-5.3-21,42.52,42.52,0,0,1,5.3-20.95,40.27,40.27,0,0,1,14.58-15.24A39.41,39.41,0,0,1,674,36.41a41.28,41.28,0,0,1,18.08,3.88A38,38,0,0,1,705.65,51a41.15,41.15,0,0,1,7.67,15.62h-19a19.14,19.14,0,0,0-7.9-9.71,23.37,23.37,0,0,0-12.36-3.26,21.6,21.6,0,0,0-10.94,3,22.42,22.42,0,0,0-8.32,8.52,25.89,25.89,0,0,0-3.15,13,26.21,26.21,0,0,0,3.15,13.05,22.19,22.19,0,0,0,8.32,8.59,21.52,21.52,0,0,0,10.94,3,24,24,0,0,0,8.56-1.5,19.43,19.43,0,0,0,7-4.64,20.52,20.52,0,0,0,4.68-7.91h18.88a41.3,41.3,0,0,1-7.52,16.23,37.46,37.46,0,0,1-13.62,11.06A41.06,41.06,0,0,1,674,120.07Z"
                />
                <path
                  class="cls-1"
                  d="M757.84,120.07a40.87,40.87,0,0,1-21.22-5.61,40.14,40.14,0,0,1-14.85-15.15,42.28,42.28,0,0,1-5.37-21.07,42.5,42.5,0,0,1,5.33-21A39.84,39.84,0,0,1,736.58,42a43.46,43.46,0,0,1,42.68,0,40.31,40.31,0,0,1,14.92,15.2,42,42,0,0,1,5.41,21,42.45,42.45,0,0,1-5.37,21.1,39.67,39.67,0,0,1-14.93,15.16A41.83,41.83,0,0,1,757.84,120.07ZM758,102.8a22.36,22.36,0,0,0,11.85-3.19,22.67,22.67,0,0,0,8.29-8.75,25.94,25.94,0,0,0,3-12.62,26,26,0,0,0-3-12.59,23,23,0,0,0-8.29-8.83,23.36,23.36,0,0,0-23.67,0,22.84,22.84,0,0,0-8.33,8.83,26,26,0,0,0-3,12.59,26.15,26.15,0,0,0,3,12.66,22.22,22.22,0,0,0,8.33,8.75A22.68,22.68,0,0,0,758,102.8Z"
                />
                <path
                  class="cls-1"
                  d="M809.5,38.79h17.35l.23,12.28a24.76,24.76,0,0,1,5.79-7.56,27.24,27.24,0,0,1,8.71-5.22,30.09,30.09,0,0,1,10.67-1.88q9.75,0,16.35,4.49A26.94,26.94,0,0,1,878.5,54q3.3,8.64,3.3,21.15v42.52H863.46V75.55q0-11.28-4.38-16.65t-12.2-5.38a16.12,16.12,0,0,0-9.36,3,21.41,21.41,0,0,0-7,8.48,28,28,0,0,0-2.65,12.35v40.3H809.5Zm108,36.76q0-11.28-4.38-16.65t-12.28-5.38a16.17,16.17,0,0,0-9.36,3,21.31,21.31,0,0,0-7,8.48,27.83,27.83,0,0,0-2.65,12.35l-3.53-22.64a42,42,0,0,1,7.67-10.24,28.84,28.84,0,0,1,9.41-6.11,29.54,29.54,0,0,1,10.86-2q9.82,0,16.42,4.49A26.94,26.94,0,0,1,932.53,54q3.3,8.64,3.3,21.15v42.52H917.49Z"
                />
                <circle class="cls-1" cx="245.63" cy="18.09" r="11.02" />
                <circle class="cls-1" cx="501.09" cy="18.09" r="11.02" />
                <circle class="cls-1" cx="615.97" cy="109.09" r="11.02" />
                <polygon
                  class="cls-2"
                  points="39.24 0.01 117.7 78.47 117.7 117.7 78.47 117.71 0 39.24 39.24 0.01"
                />
                <polygon
                  class="cls-3"
                  points="78.47 0 156.93 78.47 156.93 117.7 117.7 117.7 39.23 39.24 78.47 0"
                />
                <polygon
                  class="cls-4"
                  points="117.7 0.01 196.17 78.47 196.16 117.7 156.93 117.71 78.46 39.24 117.7 0.01"
                />
              </g>
            </g>
          </svg>
        </span>
      </a>
      <template>
        <ul>
          <template v-if="userType !== userTypeEnum.writer">
            <router-link
              :to="
                computedUserNavItems[0].specialButton &&
                  computedUserNavItems[0].specialButton.route
              "
            >
              <button class="create-project-btn bg-light text-primary">
                <span class="name">
                  {{ computedUserNavItems[0].specialButton.text }}
                </span>
                <i class="icon icon-plus text-primary"></i>
              </button>
            </router-link>
          </template>

          <!-- Don't render element in index 0 since it will throw error -->
          <template v-for="navItem in computedUserNavItems.slice(1)">
            <router-link
              :class="{
                'active-link':
                  navItem.route !== '/'
                    ? $route.path.includes(navItem.route)
                    : '',
              }"
              :to="navItem.route"
            >
              <template v-if="isSidebarSmallManually">
                <li
                  v-if="navItem.icon && navItem.route"
                  v-tooltip.right="navItem.text"
                  class="item-li"
                >
                  <span class="icon">
                    <i :class="navItem.icon"></i>
                  </span>
                  <span class="name">{{ navItem.text }}</span>
                </li>
                <li v-else class="side-header">
                  {{ navItem.text }}
                </li>
              </template>

              <template v-else>
                <li v-if="navItem.icon && navItem.route">
                  <span class="icon">
                    <i :class="navItem.icon"></i>
                  </span>
                  <span class="name">{{ navItem.text }}</span>
                </li>
                <li v-else class="side-header" v-tooltip.right="navItem.text">
                  {{ navItem.text }}
                </li>
              </template>
            </router-link>
          </template>
        </ul>
      </template>

      <div
        class="bg-light side-menu-opener cursor-pointer align-self-start"
        @click="handleSidebarManually"
      >
        <i
          class="icon"
          :class="{
            'icon-chevron-left': !isSidebarSmallManually,
            'icon-chevron-right': isSidebarSmallManually,
          }"
        ></i>
      </div>
    </nav>
    <!-- <button @click="toggleSidebar">K</button> -->
  </aside>
</template>

<script>
import userTypeEnum from '../../enums/userTypeEnum'

export default {
  name: 'TheSidebarMenu',
  props: {
    userData: {
      type: Object,
      default: {},
    },
    userType: {
      type: Number,
      required: true,
    },

    isFullscreen: {
      type: Boolean,
      default() {
        return false
      },
    },
    hasFullAccess: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleSidebarManually() {
      if (!document.body.classList.contains('small-menu')) {
        document.body.classList.add('small-menu')
        this.isSidebarSmallManually = true
      } else {
        document.body.classList.remove('small-menu')
        this.isSidebarSmallManually = false
      }
    },
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive
    },
    checkMedia() {
      if (window.matchMedia('(max-width: 991px)').matches) {
        if (!document.body.classList.contains('small-menu')) {
          document.body.classList.add('small-menu')
        }
      }
    },
  },

  mounted() {
    this.isSidebarSmallManually = false
    this.checkMedia()

    window.addEventListener('resize', e => {
      this.checkMedia()
    })
  },
  computed: {
    computedUserNavItems() {
      switch (this.userType) {
        case userTypeEnum.admin:
          return this.hasFullAccess
            ? this.sidebar.admin
            : this.sidebar.admin
                .filter(x => x.text !== 'Ayarlar')
                .filter(x => x.text !== 'Faturalar')
                .filter(x => x.text !== 'Yazar Ödemeleri')
        case userTypeEnum.publisher:
          return this.sidebar.publisher
        case userTypeEnum.writer:
          return this.userData?.main?.rel_user_writer_level?.length
            ? this.sidebar.writerPanel.writer
            : this.sidebar.writerPanel.candidate
        default:
          break
      }
    },
  },
  data() {
    return {
      userTypeEnum,
      isSidebarActive: true,
      isSidebarSmallManually: false,
      sidebar: {
        admin: [
          {
            specialButton: {
              text: 'Birebir proje',
              route: '/premium-proje-olustur',
            },
          },
          {
            icon: 'icon-home',
            text: 'Anasayfa',
            route: '/',
          },
          {
            icon: 'icon-folder',
            text: 'Projeler',
            route: '/projeler',
          },
          {
            icon: 'icon-file',
            text: 'İçerikler',
            route: '/icerikler',
          },
          {
            icon: 'icon-group',
            text: 'Kullanıcılar',
            route: '/kullanicilar',
          },
          {
            icon: 'icon-add-user',
            text: 'Başvurular',
            route: '/basvurular',
          },
          {
            icon: 'icon-invitation',
            text: 'Davetiyeler',
            route: '/davetiyeler',
          },
          {
            icon: 'icon-lira-circle',
            text: 'Yazar Ödemeleri',
            route: '/yazar-odemeleri',
          },
          {
            icon: 'icon-bill',
            text: 'Faturalar',
            route: '/faturalar',
          },
          {
            icon: 'icon-coupon',
            text: 'İndirim Kodları',
            route: '/indirim-kodlari',
          },
          {
            icon: 'icon-message-in',
            text: 'Mesajlar',
            route: '/mesajlar',
          },
          // {
          //   icon: 'icon-blog',
          //   text: 'Blog',
          //   route: '/blog',
          // },
          {
            icon: 'icon-settings',
            text: 'Ayarlar',
            route: '/ayarlar',
          },
        ],
        publisher: [
          {
            specialButton: {
              text: 'Proje oluştur',
              route: '/urun-secimi',
            },
          },
          {
            icon: 'icon-home',
            text: 'Anasayfa',
            route: '/',
          },
          {
            icon: 'icon-folder',
            text: 'Projeler',
            route: '/projelerim',
          },
          {
            icon: 'icon-group',
            text: 'Kullanıcılar',
            route: '/kullanicilar',
          },
          {
            icon: 'icon-lira-circle',
            text: 'Bakiye ve Ödemeler',
            route: '/bakiye-ve-odemelerim',
          },
          {
            icon: 'icon-message-in',
            text: 'Mesajlar',
            route: '/mesajlar',
          },
        ],
        writerPanel: {
          writer: [
            // 'Anasayfa' is not rendering without this dummy object
            {
              text: 'Dummy',
              route: '/dummy',
            },
            {
              icon: 'icon-home',
              text: 'Anasayfa',
              route: '/',
            },
            {
              icon: 'icon-file',
              text: 'İçerikler',
              route: '/iceriklerim',
            },
            {
              icon: 'icon-pool',
              text: 'İçerik Havuzu',
              route: '/icerik-havuzu',
            },
            {
              icon: 'icon-lira-circle',
              text: 'Bakiyem',
              route: '/bakiyem',
            },
            {
              icon: 'icon-message-in',
              text: 'Mesajlar',
              route: '/mesajlar',
            },
            {
              icon: 'icon-blog',
              text: 'Yazarlık',
              route: '/yazarlik',
            },
          ],
          candidate: [
            {
              specialButton: {
                text: 'Dummy',
                route: '/dummy',
              },
            },
            {
              icon: 'icon-home',
              text: 'Anasayfa',
              route: '/',
            },
            {
              icon: 'icon-blog',
              text: 'Yazarlık',
              route: '/yazarlik',
            },
            {
              icon: 'icon-message-in',
              text: 'Mesajlar',
              route: '/mesajlar',
            },
          ],
        },
      },
    }
  },
}
</script>

<style lang="sass">
@import '~Assets/sass/main'
/* Sidebar */
.side-menu-opener
  margin-top: auto
  width: 36px
  height: 36px
  border-radius: 6px
  display: flex
  align-items: center
  justify-content: center
  i
    font-family: $dm
    font-size: 1.5em
.new-logo
  display: block
  width: 140px
body.small-menu
  .side-menu
    padding: 14px !important
  .logo-container
    max-width: 32px
    overflow: hidden
  .create-project-btn
    height: 35px
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    .icon
      font-size: 20px
  .item-li
    display: flex !important
    align-items: center
    justify-content: center
    span
      margin: 0 !important
.letter-group
  fill: #0b121d
#sidebar
  position: fixed !important
  top: 0
  left: 0
  width: 240px
  height: 100%
  background: white
  display: flex
  justify-content: center
  .side-menu
    width: 100%
    padding: 24px
    display: flex
    flex-direction: column
    align-items: center
    ul
      padding: 0
      width: 100%
      margin: 0
      li
        display: block
        font-size: 0
        color: #868AB2
        margin-bottom: 6px
        padding: 8px 8px 8px 8px
        &:hover
          color: #4B52A6
        .icon
          margin-right: 15px
          font-size: 18px
          line-height: 18px
          display: inline-block
          margin-left: 10px
          vertical-align: middle
        .name
          font-family: $dm
          font-size: 14px
          display: inline-block
          vertical-align: middle
      .active
        color: #4B52A6
      .side-header
        margin-top: 25px
        margin-bottom: 25px
        font-family: $dm
        font-size: 14px

.active-link
  li
    background: #F7F8FF
    border-radius: 8px
    color: #4B52A6!important
    display: flex
    justify-content: center
.router-link-exact-active
  li
    background: #F7F8FF
    border-radius: 8px
    color: #4B52A6!important
    display: flex
    justify-content: center

.logo
  &.is-writer
    margin-bottom: 48px

  &-small
    display: none
.create-project-btn
  margin-top: 32px
  padding: 10px 16px
  margin-bottom: 24px
  font-size: 14px
  font-family: $dm
  border-radius: 6px
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  .icon
    margin-left: 10px
    font-size: 24px
@media (max-width: 991px)
  .side-menu-opener
    display: none
  #main
    margin-left: 0
    max-width: calc(100% - 80px)!important
  .box-small
    max-width: 100%
    flex: 0 0 100%
  .box-medium
    max-width: 100%
    flex: 0 0 100%
  .menu-open
    #general-container
      overflow: hidden
    #sidebar
      left: 0 !important
    #main
      margin-left: 240px !important
.menu-open.small-menu
  li
    .icon
      font-size: 18px
      line-height: 18px
      display: inline-block
      vertical-align: middle
  #main
    margin-left: 0
    max-width: calc(100% - 80px)
  .box-small
    max-width: 100%
    flex: 0 0 100%
  .box-medium
    max-width: 100%
    flex: 0 0 100%
  .menu-open
    #general-container
      overflow: hidden
    #sidebar
      left: 0 !important
    #main
      margin-left: 240px !important
  #sidebar
    width: 80px
    .side-menu
      ul
        .name
          display: none
        .side-header
          display: none
        li
          a
            .name
              display: none
  #main
    margin-left: 80px
  .fullscreen
    margin-left: 0!important
    max-width: 100%!important
  .side-menu
    .create-project-btn
      padding: 10.5px
      .icon
        margin-left: 0
  .logo-big
    display: none
  .logo-small
    display: block
    padding-left: 4px
// .sidebar-small
//   width: 80px!important
//   .side-menu
//     ul
//       .name
//         display: none!important
//       .side-header
//         display: none!important
//       li
//         a
//           .name
//             display: none!important
//   .side-menu
//     .create-project-btn
//       padding: 10.5px
//       .icon
//         margin-left: 0
//   .logo-big
//     display: none
//   .logo-small
//     display: block
//     padding-left: 4px

.cls-1
  fill: #17215b
.cls-2
  fill: #31d1ff

.cls-3 
  fill: #0273ff

.cls-4 
  fill: #1f299c

</style>
