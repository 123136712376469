const blog = {
  state: {
    categories: null,
    blogContents: null,
    blogUsers: null,
  },

  getters: {
    categories(state) {
      return state.categories
    },

    blogContents(state) {
      return state.blogContents
    },
    blogUsers(state) {
      return state.blogUsers
    },
  },

  actions: {
    setCategories({ commit }, payload) {
      commit('setCategoriesHandler', payload)
    },
    setBlogContents({ commit }, payload) {
      commit('setBlogContentsHandler', payload)
    },
    setBlogUsers({ commit }, payload) {
      commit('setBlogUsersHandler', payload)
    },
  },

  mutations: {
    setCategoriesHandler(state, payload) {
      state.categories = payload
    },
    setBlogContentsHandler(state, payload) {
      state.blogContents = payload
    },
    setBlogUsersHandler(state, payload) {
      state.blogUsers = payload
    },
  },
}

export default blog
