import Vue from 'vue'
import TheSidebarMenu from '@/components/shared/TheSidebarMenu'
import ThePageHeader from '@/components/shared/Header/ThePageHeader'
import Box from '@/components/shared/Box'
import IcerikGrid from '@/components/shared/IcerikGrid'
import IcerikStatusBoxes from '@/components/shared/IcerikStatusBoxes'
import IcerikStepList from '@/components/shared/IcerikStepList'
import StepHeader from '@/components/shared/StepHeader'
import StepFooter from '@/components/shared/StepFooter'
import Tooltip from '@/components/shared/Tooltip'
import AccountSidebarMenu from '@/components/shared/AccountSidebarMenu'
import IcerikCheckbox from '@/components/shared/IcerikCheckbox'
import IcerikRadio from '@/components/shared/IcerikRadio'
import IcerikEditor from '@/components/shared/Editor/IcerikEditor'
import IcerikPagination from '@/components/shared/IcerikPagination'
import IcerikTab from '@/components/shared/IcerikTab'
import CustomModal from '@/components/shared/CustomModal'
import IcerikNoData from '@/components/shared/IcerikNoData'
import IcerikButton from '@/components/shared/IcerikButton'
import Treeselect from '@riophae/vue-treeselect'
import IcerikSwitch from '@/components/shared/IcerikSwitch'
import IcerikRadioGroup from '@/components/shared/IcerikRadioGroup'
import IcerikLoading from '@/components/shared/IcerikLoading'

Vue.component('icerik-editor', IcerikEditor)
Vue.component('icerik-checkbox', IcerikCheckbox)
Vue.component('icerik-radio', IcerikRadio)
Vue.component('step-footer', StepFooter)
Vue.component('step-header', StepHeader)
Vue.component('the-sidebar-menu', TheSidebarMenu)
Vue.component('box', Box)
Vue.component('icerik-grid', IcerikGrid)
Vue.component('icerik-status-boxes', IcerikStatusBoxes)
Vue.component('icerik-step-list', IcerikStepList)
Vue.component('tooltip', Tooltip)
Vue.component('account-sidebar-menu', AccountSidebarMenu)
Vue.component('the-page-header', ThePageHeader)
Vue.component('icerik-pagination', IcerikPagination)
Vue.component('icerik-tab', IcerikTab)
Vue.component('custom-modal', CustomModal)
Vue.component('icerik-no-data', IcerikNoData)
Vue.component('icerik-editor', IcerikEditor)
Vue.component('icerik-button', IcerikButton)
Vue.component('icerik-switch', IcerikSwitch)
Vue.component('treeselect', Treeselect)
Vue.component('icerik-radio-group', IcerikRadioGroup)
Vue.component('icerik-loading', IcerikLoading)