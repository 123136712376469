export default {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Tıklayınız',
          'or drag to move': 'Ya da hareket ettirmek için sürükleyiniz',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Şuna çevir',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Ekle',
        },
      },
    },
    toolNames: {
      Text: 'Yazı',
      Heading: 'Başlık',
      List: 'Liste',
      Delimiter: 'Sınırlayıcı',
      Table: 'Tablo',
      Link: 'Link',
      Marker: 'İşaretleyici',
      Bold: 'Kalın',
      Italic: 'İtalik',
      Image: 'Resim'
    },
    tools: {
      link: {
        'Add a link': 'Bir link ekle',
      },
      stub: {
        'The block can not be displayed correctly.':
          'Blok görüntülenirken bir hata oluştu.',
      },
    },
    blockTunes: {
      delete: {
        Delete: 'Sil',
      },
      moveUp: {
        'Move up': 'Yukarı taşı',
      },
      moveDown: {
        'Move down': 'Aşağı taşı',
      },
    },
  },
}
