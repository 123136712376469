import './ReviseTool.css'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
/**
 * Marker Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class ReviseTool {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return 'cdx-marker'
  }

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ api }) {
    this.api = api

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */

    this.button = null

    this.inputWrapper = null
    this.input = null
    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = 'MARK'

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    }
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.button = document.createElement('button')
    this.button.type = 'button'
    this.button.classList.add(this.iconClasses.base)
    this.button.innerHTML = this.toolboxIcon

    return this.button
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return
    }

    let termWrapper = this.api.selection.findParentTag(this.tag, ReviseTool.CSS)

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper)
    } else {
      this.wrap(range)
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    /**
     * Create a wrapper for highlighting
     */
    let marker = document.createElement(this.tag)
    let sup = document.createElement('sup')

    marker.classList.add(ReviseTool.CSS)

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */

    marker.appendChild(range.extractContents())
    marker.appendChild(sup)
    range.insertNode(marker)

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(marker)
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper)

    let sel = window.getSelection()
    let range = sel.getRangeAt(0)

    let unwrappedContent = range.extractContents()
    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper)

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent)

    /**
     * Restore selection
     */
    sel.removeAllRanges()
    sel.addRange(range)
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, ReviseTool.CSS)

    // toggle marker button style depending on the highlighted or not
    this.button.classList.toggle(this.iconClasses.active, !!termTag)
    const state = termTag
    if (state) {
      this.showActions(termTag)
    } else {
      this.hideActions()
    }
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon() {
    return '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>'
  }

  renderActions() {
    this.inputWrapper = document.createElement('div')
    this.input = document.createElement('input')
    this.input.id = 'reviseInput'
    this.input.type = 'text'
    this.input.value = ''
    this.input.hidden = true
    this.input.placeholder = 'Revizenizi yazınız'
    this.inputWrapper.appendChild(this.input)
    return this.input
  }

  showActions(mark) {
    const revisionAttr = mark.getAttribute('revision')
    // Set value of input if there is already one revision attribute
    this.input.value = revisionAttr ? revisionAttr : ''
    
    this.input.oninput = e => {
      if (this.input.value) {
        this.saveRevise(mark)
      }
    }

    this.input.onkeydown = e => {
      if (e.keyCode === 13) {
        // If input is not empty
        if (this.input.value) {
          this.saveRevise(mark)
          this.api.inlineToolbar.close()
        }
      }
    }

    this.input.hidden = false
    this.input.focus()
  }

  hideActions() {
    this.input.onkeydown = null
    this.input.hidden = true
  }

  saveRevise(mark) {
    const id = this.getUniqueReviseId(mark)

    mark.setAttribute('revision', this.input.value)
    mark.setAttribute('revision-id', id)

    tippy(document.querySelectorAll('.cdx-marker'), {
      arrow: true,
      theme: 'revision',
      placement: 'top',
      onShow(instance) {
        instance.setContent(instance.reference.getAttribute('revision'))
      },
    })
  }

  getUniqueReviseId(mark) {
    const idAttr = mark.getAttribute('revision-id')
    // Create unique id
    return idAttr
      ? idAttr
      : '_' +
          Math.random()
            .toString(36)
            .substr(2, 9)
  }
  /**
   * Sanitizer rule
   * @return {{mark: {class: string}}}
   */
  static get sanitize() {
    return {
      mark: {
        class: ReviseTool.CSS,
        revision: true,
        revisionid: true,
      },
      sup: true,
    }
  }
}

export default ReviseTool
