export default {
  future: '%s',
  past: '%s önce',
  s: 'birkaç saniye',
  ss: '%d saniye',
  m: 'bir dakika',
  mm: '%d dakika',
  h: 'bir saat',
  hh: '%d saat',
  d: 'bir gün',
  dd: '%d gün',
  w: 'bir hafta',
  ww: '%d hafta',
  M: 'bir ay',
  MM: '%d ay',
  y: 'bir yıl',
  yy: '%d yıl',
}
