<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 500px;"
      class="ag-theme-alpine"
      :grid-options="gridOptions"
      :column-defs="columnDefs"
      @grid-ready="onGridReady"
      :row-multi-select-with-click="rowMultiSelectWithClick"
      :row-selection="rowSelection"
      @selection-changed="emitOnSelectionChanged"
      :pagination-page-size="pageSize"
      :pagination="true"
      :suppress-pagination-panel="disableDefaultPaginationPanel"
      @grid-has-loaded="onGridReady"
      @pagination-changed="emitOnPaginationChanged"
      :row-data="computedRowData"
      :sideBar="isAdmin ? sideBar : null"
      @rowClicked="row_click($event)"
      @filterChanged="$emit('filterChanged')"
      :rowModelType="rowModelType"
      :serverSideStoreType="serverSideStoreType"
      :animateRows="animateRows"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :cacheBlockSize="500"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import moment from 'moment'

LicenseManager.setLicenseKey(
  'CompanyName=Zeo,LicensedApplication=Zeo,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-017179,ExpiryDate=8_July_2022_[v2]_MTY1NzIzNDgwMDAwMA==b8014baab24b1caf7892a60f6fe9bc0c'
)

export default {
  name: 'IcerikGrid',
  props: {
    overlayLoadingTemplate: {
      type: String,
      default: `<span class="ag-overlay-loading-center">Yükleniyor... </span>`,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    csv_name: {
      type: String,
      default: 'icerikcom_edmin',
    },
    rowMultiSelectWithClick: {
      type: Boolean,
    },
    columnDefs: {
      type: Array,
      default() {
        return []
      },
    },
    rowData: {
      type: Array,
      default() {
        return []
      },
    },
    filterText: {
      type: String,
      default() {
        return ''
      },
    },
    pagination: {
      type: Boolean,
      default() {
        return true
      },
    },
    disableDefaultPaginationPanel: {
      type: Boolean,
      default() {
        return true
      },
    },
    pageSize: {
      type: Number,
    },
    rowSelection: {
      type: String,
      default: 'single',
    },
    selectedStatusBox: {
      type: Number,
    },
    is_discount_data: {
      type: Boolean,
      default: false,
    },
    downloadBtnClicked: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowModelType: {
      type: String,
      default: 'clientSide',
    },
    serverSideStoreType: {
      type: String,
      default: 'full',
    },
    animateRows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gridOptions: {
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          floatingFilter: false
        },
        onRowClicked: row => {
          if (this.gridApi.getFocusedCell().column.colId === 'code') {
            this.$emit('code_cell_clicked')
          } else {
            this.$emit('other_cells_clicked', row)
          }
        },
      },
      gridApi: null,
      gridColumnApi: null,
      frameworkComponents: null,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
      },
    }
  },
  components: {
    AgGridVue,
  },
  watch: {
    filterText() {
      this.gridApi.setQuickFilter(this.filterText)
    },
    pageSize(newVal) {
      this.gridApi.paginationSetPageSize(Number(newVal))
    },
    downloadBtnClicked(newVal) {
      if (newVal) {
        let params = {
          fileName: `${this.csv_name} ${moment(new Date()).format(
            'DD-MM-YYYY HH-mm-ss'
          )}`,
        }
        this.onBtnExport(params)
        this.$emit('grid_csv_downloaded')
      }
    },
  },

  mounted() {},
  methods: {
    emitOnPaginationChanged({ api }) {
      this.$emit('on-pagination-changed', {
        gridApi: api,
        totalPages: api.paginationGetTotalPages(),
        currentPage: api.paginationGetCurrentPage() + 1,
      })
    },
    onGridReady(params) {
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi

      this.$emit('on-grid-ready', params)
    },
    emitOnSelectionChanged() {
      const selectedRow = this.gridApi.getSelectedRows()

      this.$emit('on-row-selected', selectedRow)
    },
    onPageSizeChanged(newPageSize) {
      this.gridApi.paginationSetPageSize(Number(value))
    },
    row_click(row) {
      this.$emit('row_clicked', row)
    },
    onBtnExport(params) {
      this.gridApi.exportDataAsCsv(params)
    },
  },
  computed: {
    computedRowData() {
      return this.rowData
    },
  },
}
</script>

<style lang="sass">
@import '~Assets/sass/main'
@media (max-width: 991px)
  .ag-theme-alpine
    margin-bottom: 20px
.ag-theme-alpine
  margin: 10px 0px 0px 0px
  .ag-header
    background-color: #fff
    font-family: $dm
    font-size: 14px
    color: $gray !important
    border-bottom-color: #E8EAFA
  .ag-root-wrapper
    border-right: none
    border-left: none
    border-bottom: none
    border-top-color: #E8EAFA
  .ag-row
    border-color: #F7F8FF
    font-family: $dr
    font-size: 14px
    color: $dark
    &:hover
      cursor: pointer
  .ag-icon-desc
    &:before
      content: ""
      width: 10px
      height: 6px
  span.ag-header-cell-text
    color: $gray !important
    font-weight: 400

@import "ag-grid-community/dist/styles/ag-grid.css"
@import "ag-grid-community/dist/styles/ag-theme-alpine.css"
</style>
