<template>
  <div v-if="onlyIcon" class="icon custom-tooltip" :class="{'custom-tooltip-big': big, 'd-inline-block': inlineBlock}">
    <i :class="icon"></i> 
      <span class="tooltiptext">
        {{ text }}
      </span>
  </div>
  <a v-else class="button custom-tooltip" :class="{'custom-tooltip-big': big, 'd-inline-block': inlineBlock}">
    <i :class="icon"></i> 
    <span class="tooltiptext">
        {{ text }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    
    big: {
      type: Boolean,
      default() {
        return false
      }
    },

    text: {
      type: String
    },

    icon: {
      type: String
    },

    inlineBlock: {
      type: Boolean,
      default() {
        return false
      }
    },

    onlyIcon: {
      type: Boolean,
    }
  }

}
</script>

<style scoped lang="sass">
  @import '~Assets/sass/main'
  .custom-tooltip
    position: relative
    cursor: pointer
    .tooltiptext
      visibility: hidden
      opacity: 0
      background-color: $gray
      color: #fff
      text-align: center
      padding: 0 15px
      border-radius: 6px
      position: absolute
      z-index: 1
      bottom: 125%
      min-width: 100px
      left: calc(50% - 50px)
      transition: opacity 0.3s
      font-family: $dm
      font-size: 12px
      color: #fff
      &:after
        content: ""
        position: absolute
        top: 100%
        left: 50%
        margin-left: -5px
        border-width: 5px
        border-style: solid
        border-color: $gray transparent transparent transparent
    &:hover
      .tooltiptext 
        visibility: visible
        opacity: 1
  .custom-tooltip-big
    cursor: pointer
    .tooltiptext
      bottom: unset
      left: -30px
      top: 30px
      width: 240px
      padding: 15px
      &:after
        left: 35px
        top: -10px
        border-color: transparent transparent $gray transparent
</style>