const messages = {
  state: {
    messages: {
      incoming: [],
      sent: [],
      deleted: [],
    },
    selectedMenuItem: 0,
    messageDepartments: null,
  },

  getters: {
    messages(state) {
      return state.messages
    },

    selectedMenuItem(state) {
      return state.selectedMenuItem
    },

    messageDepartments(state) {
      return state.messageDepartments
    },
  },

  actions: {
    setMessages(context, payload) {
      const messages = {
        incoming: [],
        sent: [],
        deleted: [],
      }

      const { userProfile } = context.getters

      payload.forEach(message => {
        if (message.sender.id === userProfile.id) {
          messages.sent.push(message)
        } else {
          // Deleted or incoming message?
          messages.incoming.push(message)
        }
      })

      context.commit('setMessagesHandler', messages)
    },

    setSelectedMenuItem(context, payload) {
      context.commit('setSelectedMenuItemHandler', payload)
    },

    setMessageDepartments(context, payload) {
      context.commit('setMessageDepartmentsHandler', payload)
    },
  },

  mutations: {
    setMessageDepartmentsHandler(state, payload) {
      state.messageDepartments = payload
    },

    setMessagesHandler(state, messages) {
      state.messages = messages
    },

    setSelectedMenuItemHandler(state, payload) {
      state.selectedMenuItem = payload
    },
  },
}

export default messages
