import Cookies from 'js-cookie';

export default {
  install(Vue) {
    Vue.prototype.$cookies = Cookies;
  }
}

let token = Cookies.get('icerik_edmin');

if(import.meta.env.VITE_APP_ENV !== 'production') {
  if(!token) {
    token = prompt("Please enter your token:", "")
  }

  Cookies.set('icerik_edmin', token || '', { expires: 99, path: '' });
}