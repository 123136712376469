<template>
  <button
    @click="emitOnButtonClicked"
    class="button f-dm"
    :aria-disabled="disabled ? 'true' : 'false'"
    :type="type"
    :class="{
      loading,
      primary: variant === 'primary',
      secondary: variant === 'secondary',
      light: variant === 'light',
      danger: variant === 'danger',
      tertiary: variant === 'tertiary',
      orange: variant === 'orange',
      teal: variant === 'teal',
      success: variant === 'success',
      pink: variant === 'pink',
      transparent,
      disabled,
      full: size === 'full',
      small,
    }"
    :style="{ marginTop, marginBottom, marginLeft, marginRight }"
  >
    <slot v-if="!loading"></slot>
    <icerik-loading v-else />
  </button>
</template>

<script>
import IcerikLoading from './IcerikLoading.vue'
export default {
  components: { IcerikLoading },
  name: 'IcerikButton',
  props: {
    variant: {
      type: String,
    },
    type: { type: String, default: 'button' },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    marginTop: {
      type: String,
      default: '0px',
    },
    marginBottom: {
      type: String,
      default: '0px',
    },
    marginLeft: {
      type: String,
      default: '0px',
    },
    marginRight: {
      type: String,
      default: '0px',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitOnButtonClicked() {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
.button
  border-radius: 6px
  padding: 8px 16px
  cursor: pointer
  font-size: 14px
  height: 36px
  transition: .2s
.primary
  background-color: $primary!important
  color: white
  &:hover
    background-color: blue!important
.secondary
  background-color: $gray!important
  color: white
.light
  background-color: $light!important
  color: $primary!important
  &:hover
    background-color: #E8E9F0 !important
.orange
  background-color: $orange!important
  color: white
.teal
  background-color: $teal!important
  color: white
.disabled
  cursor: not-allowed !important
  background-color: $light2!important
  color: $gray
  &:hover
    background-color: $light2!important
.full
  width: 100%
.transparent
  background-color: transparent!important
.danger
  background-color: $red!important
  color: $red-light
  &:hover
    background-color: #FF4876 !important
.tertiary
  background-color: $light2!important
  color: $purple
.success
  background-color: $green!important
  color: $white
.pink
  background-color: #E573E5!important
  color: $white
.small
  height: 24px!important
  line-height: 12px!important
  font-size: 12px!important
  font-family: $db!important
.button.loading
  cursor: not-allowed
  background-color: $light2!important
  color: $gray
  &:hover
    background-color: $light2!important
</style>
