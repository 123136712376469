<template>
  <nav class="user-menu">
    <ul>
      <li
        v-for="(link, i) in userMenuLinks"
        @click="setSelectedTab(i)"
        :key="`tab_${i}`"
        :class="{ active: selectedTab === i }"
      >
        <a>{{ link }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    userMenuLinks: {
      type: Array,
    },

    selectedTab: {
      type: Number,
    },
  },

  methods: {
    setSelectedTab(idx) {
      this.$emit('on-tab-selected', idx)
    },
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
.user-menu
  ul
    padding: 0
    margin: 0
    li
      cursor: pointer
      display: block
      &:hover
        a
          background-color: $light
          color: $primary
      a
        display: block
        height: 40px
        line-height: 40px
        padding: 0px 15px
        font-family: $dm
        font-size: 14px
        color: $gray
    .active
      a
        background-color: $light
        color: $primary
        border-radius: 6px
</style>
