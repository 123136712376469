<template>
  <div
    :class="{ 'v-checkbox-group': checkboxGroup }"
    v-if="show"
    class="easy-checkbox d-flex align-items-center"
    role="checkbox"
    :aria-checked="checked"
    @click="$emit('click')"
  >
    <label
      :aria-labelledby="text"
      v-if="labelOnLeft && text"
      :style="computedStyle"
      >{{ text }}</label
    >
    <slot name="text-left"></slot>
    <input
      type="checkbox"
      class="custom"
      :value="value"
      @change="handleOnChange"
      :checked="checked"
      :disabled="disabled"
    />
    <slot name="text-right"></slot>
    <label
      :aria-labelledby="text"
      v-if="!labelOnLeft && text"
      :style="computedStyle"
      >{{ text }}</label
    >
  </div>
</template>

<script>
export default {
  name: 'IcerikCheckbox',
  watch: {},
  methods: {
    handleOnChange(event) {
      const { checked } = event.target
      if (Array.isArray(this.modelValue)) {
        const modelArr = [...this.modelValue]
        if (checked) {
          modelArr.push(this.value)
        } else {
          modelArr.splice(modelArr.indexOf(this.value), 1)
        }
        this.$emit('change', modelArr)
      } else if (typeof this.modelValue === 'boolean') {
        this.$emit('change', checked)
      } else if (typeof this.modelValue === 'object') {
        this.$emit('change', this.value)
      }
    },
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: [Boolean, Array, Object, String],
    },
    value: {
      type: [Boolean, Array, Object, String],
    },
    checked: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    checkboxGroup: {
      type: Boolean,
    },
    customStyle: {
      type: Object,
      default() {
        return {}
      },
    },
    labelOnLeft: {
      type: Boolean,
      default() {
        return false
      },
    },
    show: {
      type: Boolean,
      default() {
        return true
      },
    },
    disabled : {
      type: Boolean,
      default : false
    }
  },
  computed: {
    computedStyle() {
      const { customStyle } = this
      return {
        color: customStyle.color || '#3D4066',
        fontSize: customStyle.fontSize || '14px',
        marginLeft: customStyle.marginLeft || '8px',
        marginRight: customStyle.marginRight || '8px',
      }
    },
  },
}
</script>
<style lang="sass" scoped>
@import '~Assets/sass/main'
@supports (-webkit-appearance: none) or (-moz-appearance: none)
  input[type='checkbox'].custom,
  input[type='radio'].custom
    --active: #275EFE
    --active-inner: #fff
    --focus: 2px rgba(39, 94, 254, .3)
    --border: #BBC1E1
    --border-hover: #275EFE
    --background: #4B52A6
    --disabled: #F6F8FF
    --disabled-inner: #E1E6F9
    -webkit-appearance: none
    -moz-appearance: none
    height: 16px!important
    width: 16px!important
    outline: none
    display: inline-block
    vertical-align: top
    position: relative
    margin: 0
    cursor: pointer
    border: 1px solid #E8EAFA
    background: #F7F8FF
    transition: background .3s, border-color .3s, box-shadow .2s
    &:after
      content: ''
      display: block
      left: 0
      top: 0
      position: absolute
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s)
    &:checked
      background: #4B52A6
    &:disabled
      --b: var(--disabled)
      cursor: not-allowed
      opacity: .9
      &:checked
        --b: var(--disabled-inner)
        --bc: var(--border)

      & + label
        cursor: not-allowed
    &:not(.switch)
      width: 21px
      &:after
        opacity: var(--o, 0)

      &:checked
        --o: 1


    & + label
      font-size: 14px
      display: inline-block
      vertical-align: top
      margin-left: 5px
      margin-right: 0
      margin-bottom: 0
      font-family: $dr
      color: #3D4166


  input[type='checkbox'].custom
    &:not(.switch)
      border-radius: 2px
      &:after
        width: 5px
        height: 9px
        border: 1px solid var(--active-inner)
        border-top: 0
        border-left: 0
        left: 5px
        top: 1px
        transform: rotate(var(--r, 20deg))

      &:checked
        --r: 43deg


    &.switch
      width: 24px
      border-radius: 11px
      &:after
        left: 2px
        top: 2px
        border-radius: 50%
        width: 15px
        height: 15px
        background: var(--ab, var(--border))
        transform: translateX(var(--x, 0))

      &:checked
        --ab: var(--active-inner)
        --x: 17px

      &:disabled
        &:not(:checked)
          &:after
            opacity: .6


  input[type='radio'].custom
    border-radius: 50%
    display: none
    &:after
      width: 10px
      height: 10px
      border-radius: 50%
      background: #4B52A6
      left: 3px
      top: 3px
      opacity: 0
      transform: scale(var(--s, .7))
    &:checked + label
      background-color: #4B52A6
      span.name
        color: #fff
    & + label
      background-color: #F7F8FF
      height: 32px
      padding: 0px 10px 0px 5px
      border-radius: 100px
      padding-top: 4px
      margin-right: 0
      margin-left: 0
      span.letter
        width: 24px
        height: 24px
        border-radius: 100px
        background-color: #fff
        text-align: center
        line-height: 24px
        font-family: $dm
        font-size: 14px
        color: #3D4166
        display: inline-block
        vertical-align: middle
        margin-right: 10px
      span.name
        display: inline-block
        vertical-align: middle
  .checkbox-text
    margin-top: -2px
input[type='checkbox']:checked
  &:disabled
    background-color: #a0a0a0
</style>
