import Vue from 'vue'
import Vuex from 'vuex'

import applications from './modules/applications'
import settings from './modules/settings'
import messages from './modules/messages'
import projectDetail from './modules/project-detail'
import contentDetail from './modules/content-detail'
import blog from './modules/blog'
import userDetail from './modules/user-detail'
import writerPayments from './modules/writer-payments'
import password from './modules/password'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isFullscreen: false,
    token: null,
    userProfile: null,
    profileData: null,
  },
  getters: {
    isFullscreen(state) {
      return state.isFullscreen
    },

    userProfile(state) {
      return state.userProfile
    },

    token(state) {
      return state.token
    },

    profileData(state) {
      return state.profileData
    },
  },

  actions: {
    setIsFullscreen({ commit }, payload) {
      commit('setIsFullscreenHandler', payload)
    },
    setToken({ commit }, payload) {
      Vue.prototype.$cookies.set(payload.name, payload.token, {domain: import.meta.env.MODE === 'production' ? '.icerik.com':'localhost',path: '/'})
      // If user is logining as only admin
      if (
        payload.name !== 'icerik_writer' &&
        payload.name !== 'icerik_publisher'
      ) {
        commit('setTokenHandler', payload)
      }
    },
    getAuthToken(context, payload) {
      const token = Vue.prototype.$cookies.get('icerik_edmin')
      context.commit('getAuthTokenHandler', token)
    },
    getUserProfile(context, payload) {
      return Vue.prototype.$api.get(`profile`).then(response => {
        context.commit('getUserProfileHandler', response.data)
      })
    },
    setUserProfile(context, payload) {
      context.commit('getUserProfileHandler', payload)
    },
    setProfileData({ commit }, payload) {
      commit('setProfileDataHandler', payload)
    },
    removeAuthToken(context, payload) {
      context.commit('removeAuthTokenHandler')
    }
  },

  mutations: {
    getAuthTokenHandler(state, payload) {
      state.token = payload
    },
    getUserProfileHandler(state, payload) {
      state.userProfile = payload
    },
    setIsFullscreenHandler(state, payload) {
      state.isFullscreen = payload
    },
    setProfileDataHandler(state, payload) {
      state.profileData = payload
    },
    setTokenHandler(state, payload) {
      state.token = payload.token
    },
    removeAuthTokenHandler(state, payload) {
      state.token = null
    },
  },
  modules: {
    applications,
    settings,
    messages,
    projectDetail,
    contentDetail,
    blog,
    userDetail,
    writerPayments,
    password
  },
})
