const password = {
  state: {
    password: localStorage.getItem('edmin_upgrade_psw') || false,
  },
  getters: {
    getPassword(state) {
      const data = state.password
      if (data && data !== import.meta.env.VITE_EDMIN_BASE_PSW) {
        return false
      }
      return data
    },
  },

  actions: {
    setPassword({ commit }, payload) {
      commit('setPasswordHandler', payload)
    },
    deletePassword({commit}, payload) {
        commit('deletePasswordHandler')
    }
  },

  mutations: {
    setPasswordHandler(state, payload) {
      localStorage.setItem('edmin_upgrade_psw', payload)
      state.password = payload
    },
    deletePasswordHandler(state) {
        localStorage.removeItem('edmin_upgrade_psw')
        state.password = false
    }
  },
}

export default password
