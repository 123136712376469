import Vue from 'vue'
import interviewStatusEnums from '@/enums/interviewStatusEnums'

const applications = {
  state: {
    filterText: '',
    applications: {
      all: [],
      control: [],
      continues: [],
      approved: [],
      rejected: [],
    },
    selectedStatusBox: 0,
    applicationGridData: {},
    singleApplication: {},
    loading: false,
  },
  getters: {
    filterText(state) {
      return state.filterText
    },
    applications(state) {
      return state.applications
    },
    singleApplication(state) {
      return state.singleApplication
    },
    applicationGridData(state) {
      return state.applicationGridData
    },
    selectedStatusBox(state) {
      return state.selectedStatusBox
    },
  },
  mutations: {
    setFilterTextHandler(state, payload) {
      state.filterText = payload
    },
    getApplicationsDataHandler(state, payload) {
      state.applications = payload
    },
    getSingleApplicationDataHandler(state, payload) {
      state.singleApplication = payload
    },
    setLoadingHandler(state, payload) {
      state.loading = payload
    },
    setApplicationGridDataHandler(state, payload) {
      state.applicationGridData = payload
    },
    setSelectedStatusBoxHandler(state, payload) {
      state.selectedStatusBox = payload
    },
  },
  actions: {
    setSelectedStatusBox(context, payload) {
      context.commit('setSelectedStatusBoxHandler', payload)
    },
    setFilterText(context, payload) {
      context.commit('setFilterTextHandler', payload)
    },
    getApplicationsData(context, payload) {
      Vue.prototype.$api.get(`edmin/interview`).then(response => {
        const applications = {
          all: [],
          control: [],
          continues: [],
          approved: [],
          rejected: [],
        }
        response.data.forEach(x => {
          const commonApplicationData = {
            id: x.id,
            username: x.user.full_name,
            applyType: x.writer_level
              ? 'Seviye Yükseltme'
              : 'Yeni Dil Başvurusu',
            lang: x.language.title,
            contentScore: x.content_score ? x.content_score : '-',
            status: x.interview_status_str,
            status_id: x.interview_status_id,
            user: x.user,
            interviewType: x.interview_content?.interview_type_title,
            writerLevel: x.writer_level,
            selectedInterests: x.selected_interests,
            score : x.score,
            score_content : x.content_score,
            t_create: x.t_create,
            result : x.result_json ? x.result_json : null
          }

          if (x.interview_status_id === interviewStatusEnums.rejected) {
            const rejectedApplication = {
              ...commonApplicationData,
              status: x.interview_status_str,
              email: x.user.email,
            }
            applications.rejected.push(rejectedApplication)
          }
          if (x.interview_status_id === interviewStatusEnums.approved) {
            const approvedApplication = {
              ...commonApplicationData,
              level: x.writer_level ? x.writer_level.title : '-',
              email: x.user.email,
            }
            applications.approved.push(approvedApplication)
          }
          if (interviewStatusEnums.continues.includes(x.interview_status_id)) {
            const continuesApplication = {
              ...commonApplicationData,
              status: x.interview_status_str,
              email: x.user.email,
            }
            applications.continues.push(continuesApplication)
          }
          if (x.interview_status_id === interviewStatusEnums.control) {
            const controlApplication = {
              ...commonApplicationData,
              email: x.user.email,
              applyDate: new Date(x.created_at * 1000).toLocaleDateString(),
            }
            applications.control.push(controlApplication)
          }

          const allApplicationsData = {
            ...commonApplicationData,
            status: x.interview_status_str,
            email: x.user.email,
          }

          applications.all.push(allApplicationsData)
        })
        context.commit('getApplicationsDataHandler', applications)
      })
    },
    getSingleApplicationData(context, payload) {
      context.commit('setLoadingHandler', true)
      Vue.prototype.$api.get(`edmin/interview/${payload}`).then(response => {
        const { data } = response
        let answers = JSON.parse(data.answers)

        let answersArr = []
        if (answers) {
          Object.entries(answers).forEach((key, val) => {
            answersArr.push(answers[val])
          })

          answersArr = answersArr.map(x => {
            for (let [key, value] of Object.entries(x)) {
              let answer = value.split(' -')

              let answerPoint = answer[1]
              return {
                question: key,
                answer: answer[0],
                point: answerPoint,
              }
            }
          })
        }

        let keywords = data.interview_content?.keywords.split(',')
        keywords = keywords?.map(x => x.split('|')[1])

        const getUserType = user => {
          if (user.rel_user_writer_level.length) {
            if (user.invitation_info) {
              return 'Aktif Yazar (Referans)'
            } else {
              return 'Aktif Yazar'
            }
          } else {
            if (user.invitation_info) {
              return 'Yazar Adayı (Referans)'
            } else {
              return 'Yazar adayı'
            }
          }
        }
        const singleApplication = {
          t_create: data.t_create,
          answers: answersArr,
          content: data.content,
          contentFinishedAt: data.content_finished_at,
          id: data.id,
          score: data.score,
          selectedInterests: data.selected_interests,
          interviewContent: data.interview_content,
          user: data.user,
          interviewStatusId: data.interview_status_id,
          interviewStatusStr: data.interview_status_str,
          keywords,
          resultJSON: data.result_json,
          userType: getUserType(data.user),
        }

        context.commit('getSingleApplicationDataHandler', singleApplication)
        context.commit('setLoadingHandler', false)
      })
    },
    setApplicationGridData(context, payload) {
      context.commit('setApplicationGridDataHandler', payload)
    },
  },
}

export default applications
