const interviewStatusEnums = {
  control: 5,
  continues: [1, 2, 3, 4],
  approved: 6,
  rejected: 7,
}

Object.freeze(interviewStatusEnums)

export default interviewStatusEnums
