<template>
  <div>
    <div class="row">
      <div class="col-xl-8 col-12">
        <box class="box bg-white brd-16" v-if="todos && todos.length">
          <div id="my-tasks">
            <div class="section-title">Görevlerim - {{ todos.length }}</div>

            <div class="task-list">
              <div
                class="task-item cursor-pointer"
                v-for="todo in todos"
                :key="todo.content.id"
                @click="goToContentDetail(todo.content)"
              >
                <div
                  class="icon"
                  :class="
                    `${
                      $helpers.getColorAndBgColorByContentStatus(
                        todo.content.last_submission
                          .content_submission_status_id
                      ).color
                    } ${
                      $helpers.getColorAndBgColorByContentStatus(
                        todo.content.last_submission
                          .content_submission_status_id
                      ).bgColor
                    }`
                  "
                >
                  <i class="icon-file"></i>
                </div>

                <div class="details">
                  <div class="name">{{ todo.content.title }}</div>
                  <div
                    class="info"
                    :class="
                      `${
                        $helpers.getColorAndBgColorByContentStatus(
                          todo.content.last_submission
                            .content_submission_status_id
                        ).color
                      }`
                    "
                  >
                    {{
                      todo.content.last_submission.content_submission_status_str
                    }}
                  </div>
                </div>

                <div
                  class="hours"
                  v-if="
                    [410, 415, 420].includes(
                      todo.content.last_submission.content_submission_status_id
                    )
                  "
                  :class="
                    `${
                      $helpers.getColorsByHour(todo.content.deadline_editor)
                        .color
                    } ${
                      $helpers.getColorsByHour(todo.content.deadline_editor)
                        .bgColor
                    }`
                  "
                >
                  <span class="icon"><i class="icon-clock"></i></span>
                  <span v-if="todo.content.deadline_editor" class="name"
                    >{{
                      $helpers.getContentDeadline(
                        todo.content.last_submission.t_editor_deadline
                      )
                    }}
                    Saat</span
                  >
                  <span v-else>Yok</span>
                </div>
                <div
                  class="hours"
                  v-else
                  :class="
                    `${
                      $helpers.getColorsByHour(todo.content.deadline_editor)
                        .color
                    } ${
                      $helpers.getColorsByHour(todo.content.deadline_editor)
                        .bgColor
                    }`
                  "
                >
                  <span class="icon"><i class="icon-clock"></i></span>
                  <span v-if="todo.content.last_submission.t_deadline" class="name"
                    >{{
                      $helpers.getContentDeadline(
                        todo.content.last_submission.t_deadline
                      )
                    }}
                    Saat</span
                  >
                  <span v-else>Yok</span>
                </div>
              </div>
            </div>

            <icerik-pagination
              v-if="paginatedData"
              @limit-changed="handleOnLimitChanged"
              @go-to-prev-page="handleOnGoToPrevPage"
              @go-to-next-page="handleOnGoToNextPage"
              :current-page="page"
              :total-pages="paginatedData.totalPages"
              :total-items="paginatedData.totalItems"
            />
          </div>
        </box>

        <box v-else class="box bg-white brd-16">
          <div class="no-project-title">
            Görevlerim
          </div>
          <div class="no-project-desc">
            Şu an herhangi aktif bir göreviniz bulunmamaktadır.
          </div>
        </box>
      </div>

      <div class="col-xl-4 col-12">
        <box class="box bg-white brd-16">
          <div class="users-area" :class="{ active: showPMList }">
            <div class="current-user" v-if="selectedPM">
              <figure class="user-image bg-light text-primary">
                {{ $helpers.userAvatarName(selectedPM.full_name) }}
              </figure>

              <div class="user-details">
                <div class="user-name">
                  {{ selectedPM.full_name }}
                  <i
                    @click="showPMList = !showPMList"
                    class="icon-chevron-down cursor-pointer"
                  ></i>
                </div>
                <div class="user-statu">{{ selectedPM.user_type.title }}</div>
              </div>
            </div>

            <div class="users-area" v-if="showPMList">
              <a
                v-for="PM in computedPMs"
                @click="selectPM(PM)"
                class="user cursor-pointer"
                :key="PM.id"
              >
                <figure class="user-image">
                  {{ $helpers.userAvatarName(PM.full_name) }}
                </figure>
                <div class="user-details">{{ PM.full_name }}</div>
              </a>
            </div>
          </div>

          <div
            v-if="contentsOfPMData && ongoingContentCount"
            class="statistics"
          >
            <div class="current-statu">
              <div class="content">
                <div class="count">{{ ongoingContentCount }}</div>
                <div class="text">
                  Devam eden <br />
                  içeriğiniz var
                </div>
              </div>

              <a @click="pushToContentsWithPJM" href="#" class="button"
                ><i class="icon-chevron-right"></i
              ></a>
            </div>

            <div class="bars" style="width: 100%">
              <span
                v-for="content in Object.values(contentsOfPMData)"
                :class="content.color"
                :style="{ width: `${getBarWidthByCount(content.count)}%` }"
              ></span>
            </div>

            <div class="bars-content">
              <div
                class="block cursor-pointer"
                v-for="content in Object.values(contentsOfPMData)"
                @click="goToContentsPage(content)"
              >
                <div class="icon" :class="content.color"></div>
                <div class="content text-primary">
                  {{ content.count }} - {{ content.status }}
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <box class="box bg-white brd-16" style="margin-bottom:0;">
              <div class="no-project-desc">
                <span v-if="this.selectedPM" class="text-capitalize">{{
                  this.selectedPM.full_name
                }}</span>
                adlı kullanıcının devam içeriği bulunmamaktadır.
              </div>
            </box>
          </div>
        </box>

        <box class="box bg-primary brd-16">
          <div class="current-balance">
            <div class="icon bg-white text-primary">
              <i class="icon-file"></i>
            </div>
            <div class="details">
              <div class="price text-white" v-if="contentsWithoutEditorCount">
                {{ contentsWithoutEditorCount.count }}
              </div>
              <div class="text text-white">Birebir İçerik Sayısı</div>
            </div>
            <a
              @click="$router.push({ name: 'Contents' })"
              class="button cursor-pointer bg-blue text-white custom-tooltip"
            >
              <i
                v-tooltip="'Editörsüz içerikler'"
                class="icon-chevron-right"
              ></i>
            </a>
          </div>
        </box>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import contentStatusEnums from '@/enums/contentStatusEnums'

export default {
  name: 'Dashboard',
  data() {
    return {
      contentsWithoutEditorCount: null,
      todos: null,
      showPMList: null,
      selectedPM: null,
      contentsOfPMData: null,
      limit: 20,
      page: 1,
      paginatedData: null,
    }
  },
  watch: {
    selectedPM(newVal) {
      if (newVal) {
        this.$api.get(`edmin/dashboard/${newVal.id}/todo`).then(response => {
          this.todos = response.data
          this.paginatedData = this.$helpers.paginator(
            this.todos,
            this.page,
            this.limit
          )
          let obj = {}
          this.todos.forEach(c => {
            obj[c.content.last_submission.content_submission_status_id] = {
              count:
                obj[c.content.last_submission.content_submission_status_id]
                  ?.count || 0,
              status: c.content.last_submission.content_submission_status_str,
              status_id: c.content.last_submission.content_submission_status_id,
              color: this.$helpers.getColorAndBgColorByContentStatus(
                c.content.last_submission.content_submission_status_id
              ).bgColorHarder,
            }
            obj[c.content.last_submission.content_submission_status_id].count++
          })
          this.contentsOfPMData = obj
        })
      }
    },
  },
  computed: {
    ...mapGetters(['userProfile', 'getSelectedProjectManager']),
    ongoingContentCount() {
      if (this.contentsOfPMData) {
        return Object.values(this.contentsOfPMData).reduce(
          (n, { count }) => n + count,
          0
        )
      }
    },
    computedPMs() {
      if (this.PMs && this.selectedPM) {
        return this.PMs.filter(x => x.id !== this.selectedPM.id)
      }
    },
  },
  // t_deadline - now (timestamp) = saniye (10 rakamli olmali)
  // saniye to saat
  methods: {
    ...mapActions(['setSelectedProjectManager']),
    goToContentsPage(content) {
      if (contentStatusEnums.editorControl.includes(content.status_id)) {
        this.$router.push(`/icerikler?pjm=${this.selectedPM.id}&box=2`)
      } else if (contentStatusEnums.done.includes(content.status_id)) {
        this.$router.push(`/icerikler?pjm=${this.selectedPM.id}&box=7`)
      } else if (contentStatusEnums.cancel.includes(content.status_id)) {
        this.$router.push(`/icerikler?pjm=${this.selectedPM.id}&box=6`)
      } else if (content.status_id === 300) {
        this.$router.push(`/icerikler?pjm=${this.selectedPM.id}&box=1`)
      } else if (
        contentStatusEnums.publisherControl.includes(content.status_id)
      ) {
        this.$router.push(`/icerikler?pjm=${this.selectedPM.id}&box=3`)
      } else if (
        contentStatusEnums.editorRevise.includes(content.status_id) ||
        contentStatusEnums.publisherRevise.includes(content.status_id)
      ) {
        this.$router.push(`/icerikler?pjm=${this.selectedPM.id}&box=4`)
      }
    },
    handleOnLimitChanged(limit) {
      this.limit = limit
      this.page = 1
      this.paginatedData = this.$helpers.paginator(
        this.todos,
        this.page,
        this.limit
      )
    },

    handleOnGoToPrevPage() {
      const hasPrevPage = this.page - 1 ? this.page - 1 : null
      if (hasPrevPage) {
        this.paginatedData = this.$helpers.paginator(
          this.todos,
          --this.page,
          this.limit
        )
      }
    },
    handleOnGoToNextPage() {
      const hasNextPage =
        this.paginatedData.totalPages > this.page ? this.page + 1 : null
      if (hasNextPage) {
        this.paginatedData = this.$helpers.paginator(
          this.todos,
          ++this.page,
          this.limit
        )
      }
    },
    pushToContentsWithPJM() {
      this.$router.push(`/icerikler?pjm=${this.selectedPM.id}`)
    },
    goToContentDetail(content) {
      this.$router.push({
        name: 'ContentDetail',
        params: { contentID: content.id, contentTitle: content.title },
      })
    },
    selectPM(PM) {
      this.selectedPM = PM
      this.showPMList = false
      this.setSelectedProjectManager(PM)
    },
    getBarWidthByCount(count) {
      return count * 10
    },
    groupContentsOfPM() {
      this.$api.get(`edmin/user?group_id=1`).then(response => {
        let admins = response.data.users.filter(x => x.active)

        this.PMs = admins.filter(x => x.user_type.id === 5)

        if (!this.getSelectedProjectManager) {
          this.setDefaultSelectedPM()
        }
        if (this.getSelectedProjectManager) {
          this.selectedPM = this.PMs.filter(
            x => x.id === this.getSelectedProjectManager.id
          )[0]
        }
      })
    },
    setDefaultSelectedPM() {
      this.selectPM(this.PMs[0])
    },
  },
  mounted() {
    this.$api.get(`edmin/dashboard/count`).then(response => {
      this.contentsWithoutEditorCount = response.data
    })

    this.groupContentsOfPM()
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'
.custom-tooltip
  position: relative
  .tooltiptext
    visibility: hidden
    opacity: 0
    background-color: $gray
    color: #fff
    text-align: center
    padding: 0 15px
    border-radius: 6px
    position: absolute
    z-index: 1
    bottom: 125%
    min-width: 120px
    left: calc(50% - 60px)
    transition: opacity 0.3s
    font-family: $dm
    font-size: 12px
    color: #fff
    &:after
      content: ""
      position: absolute
      top: 100%
      left: 50%
      margin-left: -5px
      border-width: 5px
      border-style: solid
      border-color: $gray transparent transparent transparent
  &:hover
    .tooltiptext
      visibility: visible
      opacity: 1
#my-tasks
  div.section-title
    font-family: $dm
    font-size: 20px
    color: $dark
    margin-bottom: 20px
  div.task-list
    div.task-item
      font-size: 0
      border-bottom: 1px solid $light
      padding: 15px 0px
      &:last-child
        border-bottom: none
      div.icon
        display: inline-block
        vertical-align: middle
        width: 40px
        height: 40px
        border-radius: 12px
        text-align: center
        line-height: 40px
        i
          font-size: 18px
          line-height: 40px
      div.details
        padding-left: 20px
        width: calc(100% - 140px)
        display: inline-block
        vertical-align: middle
        div.name
          font-family: $dm
          font-size: 16px
          color: $dark
          line-height: 18px
        div.info
          font-family: $dm
          font-size: 14px
      div.hours
        display: inline-block
        vertical-align: middle
        width: 100px
        height: 40px
        border-radius: 18px
        text-align: center
        line-height: 40px
        font-family: $dm
        font-size: 14px
        i
          font-size: 18px
          margin-right: 7.5px
          line-height: 40px
          display: inline-block
          vertical-align: middle
          position: relative
          top: -2px
  /** Task Footer **/
  div.task-footer
    padding: 0px
    div.task-pagination
      font-family: $dm
      text-align: right
      font-size: 12px
      div.task-count
        display: inline-block
        vertical-align: middle
        select
          display: inline-block
          vertical-align: middle
          width: unset
          padding: 0px 25px 0px 10px
          background-position: 85% 50%
          margin-left: 10px
          font-size: 14px
      div.task-arrows
        display: inline-block
        vertical-align: middle
        margin-left: 15px
        div.buttons
          display: inline-block
          vertical-align: middle
          margin-left: 10px
          font-size: 24px
div.content-pool
  div.text
    font-family: $dm
    font-size: 16px
    color: #fff
  div.buttons
    text-align: right
    a.button
      display: inline-block
      vertical-align: middle
      height: 36px
      padding: 0px 20px
      border-radius: 6px
      text-align: center
      line-height: 36px
      font-family: $dm
      font-size: 14px
div.users-area
  padding-bottom: 15px
  margin-bottom: 15px
  border-bottom: 1px solid #E8EAFA
  position: relative
  div.current-user
    font-size: 0
    figure.user-image
      display: inline-block
      vertical-align: middle
      width: 56px
      height: 56px
      border-radius: 100px
      text-align: center
      line-height: 56px
      font-family: $dm
      font-size: 20px
      background-repeat: no-repeat
      background-position: 50% 50%
      background-size: cover
      margin-bottom: 0
    div.user-details
      width: calc(100% - 56px)
      display: inline-block
      vertical-align: middle
      padding-left: 15px
      position: relative
      top: -3px
      div.user-name
        font-family: $dm
        font-size: 16px
        color: $dark
        margin-bottom: 0px
        i
          color: $gray
          font-size: 28px
          line-height: 28px
          position: relative
          top: 7px
      div.user-statu
        font-family: $dr
        font-size: 14px
        color: $gray
  div.users-area
    position: absolute
    top: 65px
    background-color: #fff
    width: 100%
    z-index: 5
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04)
    border-radius: 6px
    padding-bottom: 0
    display: none
    a.user
      display: block
      width: 100%
      padding: 8px 12px
      font-size: 0
      &:first-child
        border-radius: 6px 6px 0px 0px
      &:last-child
        border-radius: 0px 0px 6px 6px
      &:hover
        background-color: #F7F8FF
      figure.user-image
        width: 32px
        height: 32px
        display: inline-block
        vertical-align: middle
        margin-bottom: 0
        background-color: #E8EAFA
        border-radius: 100px
        border: 1px solid #fff
        font-family: $dm
        font-size: 12px
        color: $primary
        text-align: center
        line-height: 32px
        background-repeat: no-repeat
        background-position: 50% 50%
        background-size: cover
      div.user-details
        display: inline-block
        vertical-align: middle
        width: calc(100% - 32px)
        padding-left: 10px
        font-family: $dr
        font-size: 14px
        color: $dark
div.users-area.active
  div.users-area
    display: block
div.statistics
  div.current-statu
    font-size: 0
    div.content
      width: calc(100% - 36px)
      display: inline-block
      vertical-align: middle
      div.count
        display: inline-block
        vertical-align: middle
        font-family: $dm
        font-size: 32px
        color: $dark
      div.text
        padding-left: 10px
        display: inline-block
        vertical-align: middle
        font-family: $dm
        font-size: 12px
        color: $gray
    a.button
      width: 36px
      height: 36px
      border-radius: 6px
      background-color: $light
      display: inline-block
      vertical-align: middle
      text-align: center
      line-height: 36px
      i
        line-height: 36px
        font-size: 22px
  .bars
    margin-bottom: 24px
    display: flex
    align-items: center
    margin-top: 16px
    span
      display: inline-block
      flex-grow: 1
      vertical-align: middle
      margin-right: 4px
      height: 8px
      border-radius: 2px
      &:last-child
        margin-right: 0
  div.bars-content
    div.block
      display: block
      font-size: 0
      margin-bottom: 5px
      div.icon
        width: 12px
        height: 12px
        border-radius: 4px
        display: inline-block
        vertical-align: middle
      div.content
        padding-left: 10px
        width: calc(100% - 12px)
        display: inline-block
        vertical-align: middle
        font-family: $dm
        font-size: 14px
        color: $dark
div.user-info
  border-bottom: 1px solid #E8EAFA
  padding-bottom: 20px
  margin-bottom: 20px
  div.name
    font-family: $dm
    font-size: 16px
    color: #3D4166
    margin-bottom: 5px
  div.details
    font-family: $dr
    font-size: 14px
    color: #868AB2
div.current-balance
  font-size: 0
  div.icon
    width: 40px
    height: 40px
    border-radius: 100px
    text-align: center
    line-height: 40px
    display: inline-block
    vertical-align: middle
    i
      font-size: 22px
      line-height: 40px
  div.details
    width: calc(100% - 76px)
    display: inline-block
    vertical-align: middle
    padding-left: 20px
    div.price
      font-family: $dm
      font-size: 20px
      color: $dark
      span
        font-family: "Arial"
    div.text
      font-family: $dr
      font-size: 14px
      color: $gray
  a.button
    display: inline-block
    vertical-align: middle
    width: 36px
    height: 36px
    background-color: $light
    text-align: center
    line-height: 36px
    color: $primary
    border-radius: 6px
    i
      font-size: 22px
      line-height: 36px
@media (max-width: 991px)
  div.content-pool
    div.buttons
      text-align: left
      margin-top: 15px
.table-footer
  margin-top: 20px
</style>
