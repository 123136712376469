<template>
  <li
    class="tab"
    @click="$emit('clicked', item)"
    :aria-current="active"
    :aria-checked="active"
    :class="{ active: active }"
  >
    <a role="button">{{ text }}</a>
  </li>
</template>

<script>
export default {
  name: 'IcerikTab',
  props: {
    active: {
      type: Boolean,
      default() {
        return false
      },
    },
    text: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
}
</script>

<style scoped lang="sass">
@import '~Assets/sass/main'

.tab
  display: inline-block
  margin-right: 25px
  font-size: 14px
  padding-bottom: 12px
  border-bottom: 1px solid transparent
  cursor: pointer
  transition: all 0.2s ease
  &:hover
    color: $primary
    border-bottom-color: $primary
  &.active
    color: $primary
    border-bottom-color: $primary
</style>
