import Vue from 'vue'

const contentDetail = {
  state: {
    contentDetail: null,
    currentSubmission: null,
  },

  getters: {
    contentDetail(state) {
      return state.contentDetail
    },
    currentSubmission(state) {
      return state.currentSubmission
    },
  },

  actions: {
    setContentDetail({ commit }, payload) {
      commit('setContentDetailHandler', payload)
    },

    setCurrentSubmission({ commit }, payload) {
      commit('setCurrentSubmissionHandler', payload)
    },
  },

  mutations: {
    setContentDetailHandler(state, payload) {
      state.contentDetail = payload
    },

    setCurrentSubmissionHandler(state, payload) {
      state.currentSubmission = payload
    },
  },
}

export default contentDetail
