<template>
  <div class="step-header">
    <div class="row align-items-center">
      <div class="col-xl-10 col-12">
        <div v-if="stepIcon" class="step-icon">
          <span @click="$emit('icon-clicked')" class="icon">
            <i :class="stepIcon"></i>
          </span>
        </div>
        <div class="step-others">
          <div class="step-title mb-2">{{ stepTitle }}</div>
          <div class="step-desc">
            <p class="text-gray">{{ stepDesc }}</p>
          </div>
        </div>
      </div>

      <div class="col-xl-2 col-12">
        <div class="step-hours">
          <div class="block bg-light text-primary">
            <i class="icon-clock"></i> <span v-tooltip="`Mülakatı tamamlamak için ${stepHours} saatin var`">{{ stepHours }} saat</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepHeader',
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    stepDesc: {
      type: String,
      required: false,
    },
    stepHours: {
      type: Number,
      required: true,
    },
    stepIcon: {
      type: String,
    },
  },
}
</script>

<style scoped lang="sass">
.icon
  cursor: pointer

.icon-chevron-up, .icon-chevron-down
  font-size: 24px
</style>
