<template>
  <modal
    :adaptive="isAdaptive"
    :name="modalName"
    @closed="$emit('closed')"
    @opened="$emit('opened')"
    :height="modalHeight"
    :width="modalWidth"
  >
    <div class="modal-top" v-if="hasSlot('title-content')">
      <div class="modal-title text-dark">
        <slot name="title-content"></slot>
      </div>
      <v-button 
        class=""
      >
        <i aria-label="Kapat" class="icon-close"></i>
      </v-button>
    </div>
    <slot></slot>
    <section class="modal-content" v-if="hasSlot('body-content')">
      <slot name="body-content"></slot>
    </section>
    <section class="modal-form" v-if="hasSlot('form-content')">
      <slot name="form-content"></slot>
    </section>
    <footer class="modal-footer" v-if="hasSlot('footer-content')">
      <slot name="footer-content"></slot>
    </footer>
  </modal>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    modalName: {
      type: String,
      required: true,
    },
    modalHeight: {
      type: String,
      default() {
        return 'auto'
      },
    },
    isAdaptive: {
      type: Boolean,
      default() {
        return true
      },
    },
    modalWidth : {
      type: String,
      default: "500"
    }
  },

  methods: {
    hasSlot(name) {
      return !!this.$slots[name];
    },
  },
}
</script>

<style lang="scss">
.button-close {
  width: 20px;
}
.vm--modal {
  max-height: 80vh !important;
  overflow-y: auto !important;
}
</style>
