import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueI18n from 'vue-i18n'
import VCalendar from 'v-calendar'
import AxiosPlugin from './plugins/axios'
import Cookies from './plugins/cookies'

import VueBreadcrumbs from 'vue-2-breadcrumbs'

import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

import moment from 'moment'
import tr from '@/config/moment/tr'

import VueRouterUserRoles from '@/plugins/vue-router-user-roles'
import Notifications from 'vue-notification'
import vClickOutside from 'v-click-outside'

import VModal from 'vue-js-modal'

import Helpers from './plugins/helpers'
import ToggleButton from 'vue-js-toggle-button'
import Popover from 'vue-js-popover'

import { initSentry } from './plugins/sentry'

initSentry(Vue);

Vue.use(ToggleButton)
Vue.use(VCalendar)
Vue.use(AxiosPlugin)
Vue.use(Cookies)
Vue.use(Tooltip)
Vue.use(VueBreadcrumbs)
Vue.use(Notifications)
Vue.use(VModal, { dynamicDefaults: { resizable: true } })
Vue.use(Helpers)
Vue.use(vClickOutside)
Vue.use(Popover)

Vue.use(VueI18n)
Vue.use(VueRouterUserRoles, { router })

import './globalComponents'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.config.productionTip = false

moment.locale('tr', {
  relativeTime: tr,
})

store.dispatch('getAuthToken').then(() => {
  store.dispatch('getUserProfile').then(() => {
    Vue.prototype.$user.set({
      role: store.getters.userProfile?.user_type?.title,
      id: store.getters.userProfile?.user_type?.id,
    })
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
})
