import './ReviseContent.css'

export default class ReviseContent {
  constructor({ data, api }) {
    this.p = null
    this.data = data
    this.api = api
  }

  static get CSS() {
    return 'revise-text'
  }

  static get sanitize() {
    return {
      p: {
        revisionid: true,
        class: ReviseContent.CSS,
        reviseidx: true,
      },
    }
  }

  render() {
    const allMarkEls = document.querySelectorAll('mark')

    if (this.data.text) {
      this.p = document.createElement('p')
      this.p.contentEditable = true
      this.p.innerHTML = `${this.data.text ? this.data.text : ''}`
      this.p.classList.add(ReviseContent.CSS)
      this.p.setAttribute('revisionid', this.data.id)
    }

    return this.p
  }

  updated() {
    const allMarkEls = document.querySelectorAll('mark')
    // const reviseInput = document.getElementById('reviseInput')

    allMarkEls.forEach(el => {
      let id = el.getAttribute('revisionID')

      if (id === this.data.id) {
        el.setAttribute('revision', this.p.innerText)
      }
    })
  }

  rendered() {
    const allRevisionContents = this.getAllRevisionContents()

    allRevisionContents.forEach((revise, idx) => {
      const reviseOldIdxAttr = revise.getAttribute('reviseidx')
      //
      this.p.setAttribute('reviseidx', idx + 1)
    })
  }

  getAllRevisionContents() {
    return document.querySelectorAll('.revise-text')
  }

  removed() {
    const allMarkEls = document.querySelectorAll('mark')

    allMarkEls.forEach(x => {
      let id = x.getAttribute('revisionID')

      if (id === this.data.id) {
        x.removeAttribute('revision')
      }
    })
  }

  save(blockContent) {
    let id = blockContent.getAttribute('revisionid')
    let reviseidx = blockContent.getAttribute('reviseidx')
    return {
      text: blockContent.innerText,
      id,
      reviseidx,
    }
  }
}
