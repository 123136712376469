<template>
  <div class="header-block notification-block" v-if="notifications">
    <div
      @click="menuIsOpen = !menuIsOpen"
      class="notification-icon cursor-pointer"
    >
      <img src="../../../assets/images/icons/bell.svg" alt="" />
      <span
        v-if="
          notifications.unread_messages.length > 0 ||
            notifications.todo.length > 0
        "
        class="dot"
      ></span>
    </div>

    <div v-if="menuIsOpen" class="notification-opener">
      <div
        class="opener-header"
        v-click-outside="headerNotificationClickedOutsideHandler"
      >
        <div class="row">
          <div class="col-6">
            <div class="title">Bildirimler</div>
          </div>
        </div>
      </div>
      <div
        class="opener-list"
        v-if="
          notifications.unread_messages.length > 0 ||
            notifications.todo.length > 0
        "
      >
        <div
          class="listing-item"
          v-for="message in computed_notifications"
          role="button"
          @click="pushToMessageDetail(message)"
          :key="message.id"
        >
          <div class="title">
            {{ message.notification_title }}
            <span v-if="!message.read && !isAdmin" class="dot"></span>
            <span v-if="!message.done && isAdmin" class="dot"></span>
          </div>
          <div class="desc">
            {{ message.notification_content }}
          </div>
        </div>

        <div
          class="listing-item"
          v-for="todo in notifications.todo"
          role="button"
          @click="pushToContentDetail(todo.content_info)"
          :key="todo.id"
        >
          <div class="title">
            {{ todo.content_info.title }}
            <span class="dot"></span>
          </div>
          <div class="desc">
            {{ todo.content_submission_status_str }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderNotification',
  data() {
    return {
      menuIsOpen: false,
    }
  },
  props: {
    notifications: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    pushToMessageDetail(message) {
      this.$router.push({
        name: 'MessageDetail',
        params: { messageID: message.id },
      })
      this.menuIsOpen = false
      this.$emit('notification-clicked')
    },

    pushToContentDetail(content) {
      this.$router.push({
        name: 'ContentDetail',
        params: { contentID: content.id, contentTitle: content.title },
      })

      this.menuIsOpen = false
      this.$emit('notification-clicked')
    },
    headerNotificationClickedOutsideHandler() {
      this.menuIsOpen = false
    },
  },

  computed: {
    computed_notifications() {
      let notifications_edited = []

      this.notifications.unread_messages.forEach(element => {
        let notifications_obj = {
          id: null,
          message_type_id: null,
          message_type_title: null,
          read: null,
          recipient_user_type_id: null,
          sender_user_type_id: null,
          notification_title: null,
          notification_content: null,
          notificaton_sender_full_name: null,
        }

        notifications_obj.id = element.id
        notifications_obj.read = element.read
        notifications_obj.sender_user_type_id =
          element.message_content_last.sender.user_type_id
        notifications_obj.recipient_user_type_id = element.message_content_last.recipient
          ? element.message_content_last.recipient.user_type_id
          : null
        notifications_obj.message_type_id = element.message_type
          ? element.message_type.id
          : null
        notifications_obj.message_type_title = element.message_type
          ? element.message_type.title
          : null
        notifications_obj.notificaton_sender_full_name = element
          .message_content_last.sender.full_name
          ? element.message_content_last.sender.full_name
          : null

        if (
          notifications_obj.message_type_id === 5 &&
          notifications_obj.sender_user_type_id === 2
        ) {
          notifications_obj.notification_title = 'Yeni Talep'
          notifications_obj.notification_content = 'Birebir proje talebi'
        } // Göndereden Yayıncı ve konu premium proje talebi ise

        if (
          (notifications_obj.message_type_id === 1 ||
            notifications_obj.message_type_id === 2 ||
            notifications_obj.message_type_id === 3 ||
            notifications_obj.message_type_id === 4) &&
          (notifications_obj.sender_user_type_id === 2 ||
            notifications_obj.sender_user_type_id === 3) &&
          notifications_obj.recipient_user_type_id === null
        ) {
          notifications_obj.notification_title =
            notifications_obj.sender_user_type_id === 2
              ? 'Yayıncı Mesajı'
              : 'Yazar Mesajı'
          notifications_obj.notification_content = `${notifications_obj.message_type_title} talebi`
        } // Gönderen yayıncı ya da yazar, konu tüm destek konuları ise

        if (
          (notifications_obj.sender_user_type_id === 1 ||
            notifications_obj.sender_user_type_id === 5) &&
          (notifications_obj.recipient_user_type_id === 2 ||
            notifications_obj.recipient_user_type_id === 3) &&
          notifications_obj.message_type_id === null
        ) {
          notifications_obj.notification_title = 'icerik.com Ekibi'
          notifications_obj.notification_content = `icerik.com Ekibi size mesaj gönderdi`
        }

        if (
          (notifications_obj.sender_user_type_id === 1 ||
            notifications_obj.sender_user_type_id === 5) &&
          (notifications_obj.recipient_user_type_id === 2 ||
            notifications_obj.recipient_user_type_id === 3) &&
          notifications_obj.message_type_id !== null
        ) {
          notifications_obj.notification_title = 'icerik.com Ekibi'
          notifications_obj.notification_content = `icerik.com Ekibi size mesaj gönderdi`
        }

        if (
          (notifications_obj.sender_user_type_id === 2 ||
            notifications_obj.sender_user_type_id === 3) &&
          (notifications_obj.recipient_user_type_id === 1 ||
            notifications_obj.recipient_user_type_id === 5) &&
          notifications_obj.message_type_id === null
        ) {
          notifications_obj.notification_title = 'Mesaj'
          notifications_obj.notification_content = `${notifications_obj.notificaton_sender_full_name} size mesaj gönderdi`
        }
        notifications_edited.push(notifications_obj)
      })

      return notifications_edited
    },
  },
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/base/variables'
div.header-block
  position: relative
  margin-right: 16px
  &:first-child
    margin-left: 0
div.notification-block
  div.notification-icon
    position: relative
    font-size: 14px
    i
      font-size: 21px
    span.dot
      display: inline-block
      width: 8px
      height: 8px
      border-radius: 100px
      background: #FF7396
      position: absolute
      top: 0
      right: 0
  div.notification-opener
    position: absolute
    top: 30px
    right: 0
    width: 280px
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04)
    border-radius: 6px
    background-color: #fff
    text-align: left
    z-index: 5
    div.opener-header
      padding: 15px
      border-bottom: 1px solid #F7F8FF
      div.title
        font-family: $dm
        font-size: 16px
        color: $dark
      div.settings
        text-align: right
        color: $gray
        font-size: 18px
    div.opener-list
      height: 270px
      overflow-y: auto
      border-bottom: 1px solid #F7F8FF
      div.listing-item
        padding: 15px
        &:hover
          background-color: #F7F8FF
        div.title
          font-family: $dm
          font-size: 12px
          color: $gray
          span.dot
            display: inline-block
            width: 8px
            height: 8px
            border-radius: 100px
            background: #FF7396
            margin-left: 5px
        div.desc
          font-family: $dr
          font-size: 14px
          color: $dark
    div.opener-menu
      ul
        padding: 0
        margin: 0
        li
          list-style: none
          a
            display: block
            padding: 0px 15px
            height: 36px
            line-height: 36px
            font-family: $dr
            font-size: 14px
            color: $gray
            &:hover
              background-color: #F7F8FF
              color: $dark
</style>
